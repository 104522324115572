import { UpcomingChallenges } from "./component";
import { asConfiguredComponent } from "../../helper/configured-component/configured-component";
import {
  fetchUpcomingChallengesReducer,
  fetchUpcomingContestTemplateReducer,
} from "./fetcher";

//STEP: 2 - Import fetcher reducer and pass it to asConfiguredComponent

const Component = asConfiguredComponent(
  [fetchUpcomingChallengesReducer, fetchUpcomingContestTemplateReducer],
  UpcomingChallenges
);
export default Component;
