import { createAsyncThunk } from "@reduxjs/toolkit";
import httpFetch from "../../helper/utils/http-fetch";
export const fetchContests = createAsyncThunk(
  "createChallenge/fetchGameType",
  async () => {
    try {
      const { data: contestData } = await httpFetch({
        mehtod: "GET",
        relativePath: `/contest?limit=500&skip=0`,
        withCredentials: true,
      });
      console.log("contestData", contestData);
      return contestData;
    } catch (err) {
      console.log("err", err);
    }
  }
);
