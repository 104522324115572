import { createSlice } from '@reduxjs/toolkit'
// import { fetchGameType } from "../../Pages/CreateChallenge/fetcher"

export const configureSlice = (path, fetcher, initialValue) => {
    const CreateChallengeSlice = createSlice({
        name: path,
        initialState: {
            [path]: initialValue
        },
        reducers: {
            setGameType: (state, { payload }) => {
                state.fetchGameType = [...payload]
            }
        },
        extraReducers: {
            [fetcher.fulfilled]: (state, { payload }) => {
                return { ...state, [path]: payload }
            }
        }
    });

    return CreateChallengeSlice;
}
