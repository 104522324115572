import styled from "styled-components";
import { Button } from "../../components/elements/button";

const Wrapper = styled.div`
  margin-top: -5rem;
  width: 100%;
  height: min-content;
  display: flex;
  justify-content: center;
`;
const ContestHeading = styled.p`
  color: black;
  font-weight: 700;
  font-size: 1rem;
  margin: 0rem 0 0 0.8rem;
  padding-top: 0.8rem;
  margin-bottom: 1rem;
  text-align: center;
  grid-column: 2/3;
`;
const CreateContestWrapper = styled.div`
  width: 40%;
  margin-bottom: 2rem;
  background-color: white;
  border-left: 0.1rem solid #e6e6e6;
  box-shadow: 0px 4px 11px rgba(9, 30, 66, 0.12);
  border-radius: 0 0.3rem 0.3rem 0;
  column-gap: 0.3rem;
`;

const Form = styled.form``;

const FormInputDate = styled.input`
  background: "#000";
  border: 0.1rem solid #d7d7d7;
  border-radius: 0.2rem;
  padding: 0.3rem;
  font-weight: 700;
  color: "red";
  grid-column: 2/3;
  // padding:0.5rem;
  background-color: #eff1fe;
  border: none;
  color: #3634a8;
  width: 50%;
`;
const Trackerlabel = styled.label`
  font-size: 1rem;
  font-weight: 600;
`;
const Trackerselect = styled.select`
  padding: 0.7rem;
  background-color: #eff1fe;
  border: none;
  color: #3634a8;
  // margin-left:.1rem;
  width: 50%;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.7rem;
  margin-left: 3rem;
`;
const Input = styled.input`
  width: 90%;
  background-color: #eff1fe;
  border: none;
  color: #3634a8;
  padding: 0.7rem;
  // border:none;
`;
const CreateChallengeButton = styled(Button.Primary).withConfig({
  displayName: "CreateChallengeButton",
})`
  width: 70%;
  padding: 1rem;
  margin-top: 1rem;
  color: red;
  margin-left: 3rem;
`;
const PreviewButton = styled(Button.Secondary).withConfig({
  displayName: "CreateChallengePreviewButton",
})`
  width: 70%;
  padding: 1rem;
  margin-top: 1rem;
  margin-left: 3rem;
  margin-bottom: 1rem;
`;

export {
  Wrapper,
  ContestHeading,
  CreateContestWrapper,
  InputWrapper,
  Form,
  FormInputDate,
  Trackerlabel,
  Trackerselect,
  Input,
  CreateChallengeButton,
  PreviewButton,
};
