import { configureStore } from "@reduxjs/toolkit";
import { allReducer } from "../../redux/reducers";
// import CreateChallengeSlice from "../Pages/CreateChallenge/fetcher"

const setUpStore = (reducer) => {
  const store = configureStore({
    reducer: {
      CreateChallenge: reducer,
      ...allReducer,
    },
  });
  return store;
};

export { setUpStore };
