import { Plugin } from "../../helper/plugins/redux-core-plugin";

// create fetcher

export const { fetcher: fetchWithdrawalReducer, fetchWithdraw } = Plugin(
  "fetchWithdraw",
  {
    method: "GET",
    apiEndpoint: `/admin/withdraw/transaction?limit=30&skip=0`,
    withCredentials: true,
  }
);
