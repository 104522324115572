import { Plugin } from "../../helper/plugins/redux-core-plugin";

//STEP: 1 - Create Fetcher

export const {
  fetcher: fetchUpcomingChallengesReducer,
  fetchUpcomingChallenges,
} = Plugin("fetchUpcomingChallenges", {
  method: "GET",
  apiEndpoint: `/challenge?limit=30&skip=0&status=NEW`,
  withCredentials: true,
});

export const {
  fetcher: fetchUpcomingContestTemplateReducer,
  fetchUpcomingContestTemplate,
} = Plugin("fetchUpcomingContestTemplate", {
  method: "GET",
  apiEndpoint: `/admin/contest_templates?limit=50&skip=0`,
  withCredentials: true,
});
