import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button } from "../../components/elements/button";

const Wrapper = styled.div`
  margin-left: 8rem;
  margin-top: -3rem;
  margin-bottom: 2rem;
  width: 85%;
  display: flex;
  justify-content: space-between;
`;

const DropdownContainer = styled.div``;

const CreateContestWrapper = styled.div``;

const CreateChallangeButton = styled(Button)`
  width: 10rem;
  background-color: #e5e5e5;
  border: 1px solid gray;

  &:hover {
    background-color: white;
  }
`;

const CreateChallangeLink = styled(Link)`
  text-decoration: none;
  color: white;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: black;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem 0;
`;

export {
  CreateContestWrapper,
  DropdownContainer,
  Wrapper,
  CreateChallangeLink,
  CreateChallangeButton,
  PaginationWrapper,
};
