import Home from "./component";
import { asConfiguredComponent } from "../../helper/configured-component/configured-component";
import {
  fetchTotalUsersReducer,
  fetchTotalSignupCountReducer,
  fetchTotalGamePlayedReducer,
  fetchFirstDepositUserCountReducer,
  fetchThirdDepositUserCountReducer,
  fetchFifthDepositUserCountReducer,
} from "./fetcher";

//STEP: 2 - Import fetcher reducer and pass it to asConfiguredComponent

const Component = asConfiguredComponent(
  [
    fetchTotalUsersReducer,
    fetchTotalSignupCountReducer,
    fetchTotalGamePlayedReducer,
    fetchFirstDepositUserCountReducer,
    fetchThirdDepositUserCountReducer,
    fetchFifthDepositUserCountReducer,
  ],
  Home
);
export default Component;
