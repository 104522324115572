import styled from "styled-components";

const Select = styled.select`
  margin: 0;
  padding: 0.75em 1.5em;
  outline: none;
  cursor: pointer;

  text-transform: uppercase;
  /* Styling the select text color */
  color: darken(#1fbded, 35%);

  width: ${({ variations }) => {
    if (variations.typeStyle === "primary") return "auto";
    else if (variations.typeStyle === "secondary") return "20%";
  }};
  border-radius: 0;
  background-color: ${({ variations }) => {
    if (variations.typeStyle === "primary") return "#e5e5e5";
    else if (variations.typeStyle === "secondary") return "white";
  }};
  border: ${({ variations }) => {
    if (variations.typeStyle === "primary") return "none";
    else if (variations.typeStyle === "secondary") return "1px solid gray";
  }};
`;
const Option = styled.option``;

export { Select, Option };
