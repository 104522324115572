import React, { useEffect, useRef } from "react";
import funnelFunction from "../../../helper/utils/funnel-function";
import { FunnelHeading, SvgWrappingElement } from "./styles";
import * as d3 from "d3";

function FunnelChart({ head, data, dimension, showPercentage = true }) {
  const refs = useRef();

  useEffect(() => {
    d3.select(refs.current).html("");
    if (data.length) {
      const chart = funnelFunction(refs, data, dimension, showPercentage);

      chart
        .size([dimension.width, dimension.height - 100]) //size of the svg
        .font({ size: { label: 14, value: 12, percentage: 12 } }) //  { label: font of text label, value: font of text inside funnel, percentage: font of the percentage}
        .options({
          style: "3d", //3d, 2d, plain
          percentage: "first", // first, previous
          streamlined: false, // false to show highest data value as top chart layer and true to show it outside chart
          showPercentage: showPercentage,
        })
        .data(data)
        .render();
    }
  }, [data]);

  return (
    <div style={{ width: dimension.width, height: dimension.height }}>
      <FunnelHeading style={{ textAlign: "center" }}>{head}</FunnelHeading>
      <SvgWrappingElement ref={refs}></SvgWrappingElement>
    </div>
  );
}

export default FunnelChart;
