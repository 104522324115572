import Dropdown from "./component";

import { asVariation } from "../../../helper/variations";

Dropdown.Primary = asVariation(Dropdown, "primary", {
  typeStyle: "primary",
});

Dropdown.Secondary = asVariation(Dropdown, "secondary", {
  typeStyle: "secondary",
});

export { Dropdown };
