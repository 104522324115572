import { createSlice } from "@reduxjs/toolkit";

export const SetLoaderSlice = createSlice({
  name: "setLoaderSlice",
  initialState: {
    isLoading: false,
    responseState: [],
  },
  reducers: {
    setIsLoading: (state, { payload }) => {
      return {
        ...state,
        isLoading: payload,
      };
    },

    addToResponseState: (state, { payload }) => {
      const responseItem = {
        isError: payload.isError,
        isSuccess: payload.isSuccess,
        message: payload.message,
      };

      state.responseState.push(responseItem);
    },

    removeFromResponseState: (state, { payload }) => {
      const newResponseState = state.responseState.slice(1);

      return {
        ...state,
        responseState: [...newResponseState],
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setIsLoading, addToResponseState, removeFromResponseState } =
  SetLoaderSlice.actions;
export const getLoader = (state) => state?.SetLoader?.gameTisLoadingype;
export default SetLoaderSlice.reducer;
