import React, { useState, Fragment, useEffect } from "react";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";

import { DetailsInPopup } from "../details-in-popup";
import { DetailsInSlider } from "../details-in-slider";
import TableHeader from "../../compositions/table/table-header";
import {
  SectionDiv,
  TableHead,
  TableBody,
  ColumnData,
  TableRow,
  CardItem,
  TableRowWrapper,
  LabelWrapper,
  Label,
  Value,
  EmptyTableMessage,
} from "./styles";

export const Table = ({
  variations,
  headers,
  tableData,
  hasPopupEnabled = false,
  hasSliderEnabled = false,
  callbackFunctionForTable,
  tableSortFunction,

  additionalData = {},
  tableClickHandler,
  childClickHandler,
  clicked,
  setClicked,
}) => {
  // states

  const [openPopupWindow, setOpenPopupWindow] = useState(false);

  const [sortOrder, setSortOrder] = useState("DESC");

  const [sortBy, setSortBy] = useState("created_on");

  useEffect(() => {
    const query = {
      sort_by: sortBy,
      sort_order: sortOrder,
    };

    if (callbackFunctionForTable) {
      callbackFunctionForTable(query);
    }
  }, [sortOrder, sortBy]);

  const isSelectedCardEmpty = !Object.keys(additionalData || {}).length;

  const handleToggleView = (index) => {
    if (index === clicked) setClicked(null);
    else setClicked(index);
  };

  const handleCardClick = (rowDataArray, index) => {
    handleToggleView(index);
    tableClickHandler(rowDataArray);
    setOpenPopupWindow(true);
  };

  return (
    <Fragment>
      <SectionDiv>
        {headers && (
          <TableHead>
            {headers.map((header, index) => {
              return (
                <TableHeader
                  key={index}
                  header={header}
                  totalColumns={headers.length}
                  sortOrder={sortOrder}
                  setSortOrder={setSortOrder}
                  setSortBy={setSortBy}
                  showIcon={header.sort_by === sortBy}
                  tableSortFunction={tableSortFunction}
                />
              );
            })}
          </TableHead>
        )}

        {/******** Single-Line Table *********/}

        {variations.typeStyle === "singleline" && (
          <TableBody>
            {tableData.singleLineData && tableData.singleLineData.length ? (
              tableData.singleLineData.map((rowDataArray, index) => (
                <TableRowWrapper key={index}>
                  <TableRow
                    onClick={() => handleCardClick(rowDataArray, index)}
                  >
                    {rowDataArray.rowData.map((data, index) => (
                      <ColumnData key={index} totalColumns={headers.length}>
                        {data?.[headers?.[index].colName]}
                      </ColumnData>
                    ))}
                  </TableRow>

                  {/* slider component  */}
                  {!isSelectedCardEmpty && hasSliderEnabled && (
                    <DetailsInSlider.Primary
                      active={index === clicked}
                      detailsInSliderData={additionalData}
                      sliderMethodHandler={childClickHandler}
                    />
                  )}
                </TableRowWrapper>
              ))
            ) : (
              <EmptyTableMessage style={{ margin: "2rem" }}>
                No data found
              </EmptyTableMessage>
            )}

            {/* popup window component  */}
            {!isSelectedCardEmpty && hasPopupEnabled && (
              <DetailsInPopup
                openPopupWindow={openPopupWindow}
                setPopupOpen={setOpenPopupWindow}
                detailsInPopupData={additionalData}
                popupOnclickHandler={childClickHandler}
              />
            )}
          </TableBody>
        )}

        {/* /******** Multi-Line Table ******** */}

        {variations.typeStyle === "multiline" && (
          <TableBody>
            {tableData.multiLineData && tableData.multiLineData.length ? (
              tableData.multiLineData.map((rowDataArray, i) => {
                return (
                  <TableRowWrapper key={i}>
                    <TableRow onClick={() => handleCardClick(rowDataArray, i)}>
                      {/* <ColumnData totalColumns={headers.length}> */}
                      {rowDataArray.rowData.map((data, index) => {
                        return (
                          <ColumnData key={index} totalColumns={headers.length}>
                            <CardItem>
                              <LabelWrapper>
                                <MeetingRoomIcon></MeetingRoomIcon>
                                <Label>{data.first.label}</Label>
                              </LabelWrapper>
                              <Value>{data.first.value}</Value>
                            </CardItem>
                            <CardItem>
                              <LabelWrapper>
                                <EmojiEventsIcon></EmojiEventsIcon>
                                <Label>{data.second.label}</Label>
                              </LabelWrapper>
                              <Value>{data.second.value}</Value>
                            </CardItem>
                          </ColumnData>
                        );
                      })}
                    </TableRow>

                    {!isSelectedCardEmpty && hasSliderEnabled && (
                      <DetailsInSlider.Secondary
                        active={i === clicked}
                        detailsInSliderData={additionalData}
                        sliderMethodHandler={childClickHandler}
                      />
                    )}
                  </TableRowWrapper>
                );
              })
            ) : (
              <EmptyTableMessage style={{ margin: "2rem" }}>
                No Data Found
              </EmptyTableMessage>
            )}
          </TableBody>
        )}
      </SectionDiv>
    </Fragment>
  );
};

/*
Table.SingleLine = ({ headers, data }) => (
  <Table tableStyle="singleline" headers={headers} data={data} />
);

Table.MultiLine = ({ headers, data }) => (
  <Table tableStyle="multiline" headers={headers} data={data} />
);
*/
