import { configureAsyncThunk } from "./configure-async-thunk";
import { configureSlice } from "./configure-slice";
import { setUpStore } from "./configure-store";

export const Plugin = (
  path,
  { method = "GET", apiEndpoint = "", withCredentials = true }
) => {
  const fetcher = configureAsyncThunk(path, {
    method,
    apiEndpoint,
    withCredentials,
  });

  const slice = configureSlice(path, fetcher, []);
  // setUpStore(CreateChallengeSlice.reducer)

  return { fetcher, [path]: slice.reducer };
};
