import styled from "styled-components";
import { Button } from "../../components/elements/button";

const Box = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  background-color: #f0f0f0;
`;

const Error = styled.h2`
  margin-top: 5rem;
`;

const Reload = styled(Button)`
  height: 2rem;
  padding: 0 1rem;
`;

export { Box, Error, Reload };
