import { Plugin } from "../../helper/plugins/redux-core-plugin";

// const path = 'fetchGameType';

export const { fetcher: fetchGameTypeReducer, fetchGameType } = Plugin(
  "fetchGameType",
  {
    method: "GET",
    apiEndpoint: `/admin/game_types`,
    withCredentials: true,
  }
);

export const { fetcher: fetchContestTemplateReducer, fetchContestTemplate } =
  Plugin("fetchContestTemplate", {
    method: "GET",
    apiEndpoint: `/admin/contest_templates?limit=50&skip=0`,
    withCredentials: true,
  });

//STEP: 1 - Create Fetcher
export const { fetcher: fetchTeamReducer, fetchTeam } = Plugin("fetchTeam", {
  method: "GET",
  apiEndpoint: `/team?limit=50&skip=0`,
  withCredentials: true,
});

export const {
  fetcher: fetchChallengeTemplateReducer,
  fetchChallengeTemplate,
} = Plugin("fetchChallengeTemplate", {
  method: "GET",
  apiEndpoint: `/admin/challenge_templates?limit=50&skip=0`,
  withCredentials: true,
});

// const fetchTeam = configureAsyncThunk('createChallenge/fetchTeam', {
//     method: "GET",
//     apiEndpoint: `/team?limit=50&skip=0`,
//     withCredentials: true,
// });

// const fetchContestTemplate = configureAsyncThunk('createChallenge/fetchContestTemplate', {
//     mehtod: "GET",
//     apiEndpoint: `/admin/contest_templates?limit=50&skip=0`,
//     withCredentials: true,
// });

// const fetchChallengeTemplate = configureAsyncThunk('createChallenge/fetchChallengeTemplate', {
//     mehtod: "GET",
//     apiEndpoint: `/admin/challenge_templates?limit=50&skip=0`,
//     withCredentials: true,
// });

// export { fetcher };

// export default fetchGameType
