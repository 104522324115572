import * as d3 from "d3";
import FunnelChartClass from "../../components/compositions/funnel-chart/funnel-chart-class";

const funnelFunction = (refs, data, dimension, showPercentage) => {
  const svgCanvas = d3
    .select(refs.current)
    .append("svg")
    .attr("width", dimension.width)
    .attr("height", dimension.height);

  const chart = new FunnelChartClass(svgCanvas);
  return chart;
};

export default funnelFunction;
