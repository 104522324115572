import styled from "styled-components";

const DataField = styled.p`
  letter-spacing: 2px;
  margin-left: 2rem;
  margin-top: 1rem;
  font-size: 20px;
`;
const DialogImage = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #eaeaea;
`;

const UserImageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
`;

const UserImg = styled.img`
  width: 20rem;
  height: auto;

  object-fit: contain;
  object-position: center;
  border-radius: 10px;
`;

const ImageLabel = styled.span``;

const TextArea = styled.textarea`
  width: 22.1rem;
  outline: none;
  margin-top: 1.5rem;
  margin-left: 1.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  padding: 0.5rem;
`;

export {
  TextArea,
  DataField,
  DialogImage,
  UserImageContainer,
  UserImg,
  ImageLabel,
};
