import DetailsInSlider from "./component";
import { asVariation } from "../../../helper/variations";

DetailsInSlider.Primary = asVariation(DetailsInSlider, "primary", {
  typeStyle: "primary",
});
DetailsInSlider.Secondary = asVariation(DetailsInSlider, "secondary", {
  typeStyle: "secondary",
});

export { DetailsInSlider };
