export const asVariation = (
  Component,
  variationName,
  variationProps,
  additionalProps = {}
) => {
  const Wrapper = (props) => {
    return (
      <Component
        {...props}
        {...additionalProps}
        variationName={variationName}
        variations={variationProps}
      />
    );
  };

  return Wrapper;
};
