import { createSlice } from "@reduxjs/toolkit";

const getInitialData = () => {
  const initialValue = window.localStorage.getItem("login");
  if (initialValue) {
    return JSON.parse(initialValue);
  } else return { isAuthenticate: false };
};

export const loginSlice = createSlice({
  name: "Login",
  initialState: getInitialData(),
  reducers: {
    setIsAuthenticate: (state, { payload }) => {
      const newValue = { isAuthenticate: payload };
      window.localStorage.setItem("login", JSON.stringify(newValue));
      return newValue;
    },
  },
});
export const { setIsAuthenticate } = loginSlice.actions;
export default loginSlice.reducer;
