import React, { useState } from "react";
import { Dropdown } from "../../elements/dropdown";
import {
  SliderData,
  SliderDataWrapper,
  SliderWrapper,
  SliderDataTag,
  SliderDataField,
  SliderAction,
  InputField,
  ButtonBox,
  ActionButton,
  ContestWrapper,
  ContestData,
  SliderHeader,
  MultilineSliderAction,
} from "./style";

function DetailsInSlider({
  detailsInSliderData = {},
  active,
  sliderMethodHandler = [],
  variations,
}) {
  // states

  const [comment, setComment] = useState("");
  const [externalTransactionId, setExternalTransactionId] = useState("");
  const [contestName, setContestName] = useState("");
  const [bonus, setBonus] = useState("");
  const [contest, setContest] = useState();

  const {
    popupData,
    sliderData,
    dropdownOptions,
    challangeId,
    status,
    contestList,
    sliderHeaders,
  } = detailsInSliderData;

  const [proceedWithdrawal, rejectWithdrawal] = sliderMethodHandler;
  const [createContestHandler] = sliderMethodHandler;

  const payloadDataForWithdrawal = {
    id: sliderData?.id,
    comment,
    external_transaction_id: externalTransactionId,
  };

  const payloadDataForChallanges = {
    challenge_id: challangeId,
    name: contestName,
    template: contest,
    percentage_bonus: parseInt(bonus),
  };

  const handleProcessButtonClick = () => {
    proceedWithdrawal(payloadDataForWithdrawal);
  };
  const handleRejectButtonClick = () => {
    rejectWithdrawal(payloadDataForWithdrawal);
  };

  const handleCreateButtonClick = () => {
    createContestHandler(payloadDataForChallanges);
  };

  return (
    <>
      {active && (
        <SliderWrapper active>
          {variations.typeStyle === "primary" && (
            <SliderDataWrapper>
              {popupData?.map((item, index) => {
                return (
                  <SliderData key={index}>
                    <SliderDataTag>{item.label} :</SliderDataTag>
                    <SliderDataField>{item.value}</SliderDataField>
                  </SliderData>
                );
              })}

              <SliderAction onSubmit={(e) => e.preventDefault()}>
                <InputField
                  type="text"
                  placeholder="Comment"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
                <InputField
                  type="text"
                  placeholder="Transaction Id"
                  value={externalTransactionId}
                  onChange={(e) => setExternalTransactionId(e.target.value)}
                />
                <ButtonBox>
                  <ActionButton
                    label="Process"
                    variations={{ typeStyle: "success" }}
                    onClickHandler={handleProcessButtonClick}
                  />
                  <ActionButton
                    label="Reject"
                    variations={{ typeStyle: "error" }}
                    onClickHandler={handleRejectButtonClick}
                  />
                </ButtonBox>
              </SliderAction>
            </SliderDataWrapper>
          )}

          {variations.typeStyle === "secondary" && (
            <SliderDataWrapper>
              {sliderHeaders?.map((header) => {
                return (
                  <SliderHeader totalColumns={sliderHeaders.length}>
                    {header}
                  </SliderHeader>
                );
              })}
              {contestList?.map((item, index) => {
                const contestData = [
                  item.name,
                  item.type,
                  item.win_chance,
                  item.total_winnings,
                  `${item.current_spots}/${item.total_spots}`,
                  item.entry_fees,
                ];
                return (
                  <ContestWrapper key={index} sequence={index}>
                    {contestData.map((el, index) => {
                      return (
                        <ContestData
                          totalColumns={contestData.length}
                          key={index}
                        >
                          {el}
                        </ContestData>
                      );
                    })}
                  </ContestWrapper>
                );
              })}

              <MultilineSliderAction
                status={status}
                onSubmit={(e) => e.preventDefault()}
              >
                <InputField
                  type="text"
                  name="name"
                  placeholder="Enter Contest Name"
                  value={contestName}
                  onChange={(e) => setContestName(e.target.value)}
                />
                <InputField
                  type="number"
                  name="bonus"
                  placeholder="Enter Bonus"
                  value={bonus}
                  onChange={(e) => setBonus(e.target.value)}
                  required
                />
                <Dropdown.Secondary
                  value={contest}
                  options={dropdownOptions}
                  handleChange={(e) => setContest(e.target.value)}
                />
                <ButtonBox>
                  <ActionButton
                    label="Create Contest"
                    variations={{ typeStyle: "success" }}
                    onClickHandler={handleCreateButtonClick}
                  />
                </ButtonBox>
              </MultilineSliderAction>
            </SliderDataWrapper>
          )}
        </SliderWrapper>
      )}
    </>
  );
}

DetailsInSlider.defaultProps = {
  variations: {
    typeStyle: "primary",
  },
};

export default DetailsInSlider;
