import React from "react";
import { useSelector } from "react-redux";
import { Loader } from "../../elements/loader";
import { Snackbar } from "../../elements/snackbar";
import { Wrapper } from "./style";

function Component({ children }) {
  const state = useSelector((state) => state);

  const {
    SetLoader: { isLoading: loadingState },
  } = state;

  return (
    <>
      <Wrapper>
        {loadingState && <Loader />}
        <Snackbar loadingState={state.SetLoader.responseState} />
        {children}
      </Wrapper>
    </>
  );
}

export default Component;
