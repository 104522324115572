import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FunnelChart } from "../../components/compositions/funnel-chart";
import { DatePicker } from "../../components/elements/date-picker";
import {
  fetchTotalUsersReducer,
  fetchTotalSignupCountReducer,
  fetchTotalGamePlayedReducer,
  fetchFirstDepositUserCountReducer,
  fetchThirdDepositUserCountReducer,
  fetchFifthDepositUserCountReducer,
} from "./fetcher";

import {
  ChartWrapper,
  DatePickerBox,
  Heading,
  HomeHeader,
  Paragraph,
  TitleWrapper,
  TotalUsers,
  TotalUsersBody,
  TotalUsersBox,
  TotalUsersLabel,
  UsersDetailsWrapper,
} from "./styles";

export default function Component(props) {
  // states
  const [userMatrixData, setUserMatrixData] = useState([]);
  const [depositedUser, setDepositedUser] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [startDate, setStartDate] = useState(
    new Date("2022-01-01T06:58:05.556Z")
  );
  const [endDate, setEndDate] = useState(new Date());
  const dispatch = useDispatch();
  const fetchedHomeData = props?.fetchedData;

  const startDateUtc = new Date(startDate).toISOString();
  const endDateUtc = new Date(endDate).toISOString();

  useEffect(() => {
    dispatch(
      fetchTotalUsersReducer(
        `/admin/count_user?start_time=${startDateUtc}&end_time=${endDateUtc}&all_user=true`
      )
    );
    dispatch(
      fetchTotalSignupCountReducer(
        `/admin/count_user?start_time=${startDateUtc}&end_time=${endDateUtc}&all_user=false`
      )
    );
    dispatch(
      fetchTotalGamePlayedReducer(
        `/admin/user_join_contest?start_time=${startDateUtc}&end_time=${endDateUtc}&all_user=false`
      )
    );
    dispatch(
      fetchFirstDepositUserCountReducer(
        `/admin/user_deposit_count?start_time=${startDateUtc}&end_time=${endDateUtc}&min_count=1`
      )
    );
    dispatch(
      fetchThirdDepositUserCountReducer(
        `/admin/user_deposit_count?start_time=${startDateUtc}&end_time=${endDateUtc}&min_count=3`
      )
    );
    dispatch(
      fetchFifthDepositUserCountReducer(
        `/admin/user_deposit_count?start_time=${startDateUtc}&end_time=${endDateUtc}&min_count=5`
      )
    );
  }, [dispatch, startDateUtc, endDateUtc]);

  useEffect(() => {
    setTotalUsers(fetchedHomeData?.fetchTotalUsersCount);

    const data = [
      { stage: "Login", value: fetchedHomeData?.fetchTotalSignupCount },
      {
        stage: "Game Played",
        value: fetchedHomeData?.fetchTotalGamePlayedCount,
      },
      {
        stage: "Money Deposited",
        value: fetchedHomeData?.fetchFirstDepositUserCount,
      },
    ];
    setUserMatrixData(data);

    const numberOfDepositedUser = [
      {
        stage: "First Deposite",
        value: fetchedHomeData?.fetchFirstDepositUserCount,
      },
      {
        stage: "Third Deposite",
        value: fetchedHomeData?.fetchThirdDepositUserCount,
      },
      {
        stage: "Fifth Deposite",
        value: fetchedHomeData?.fetchFifthDepositUserCount,
      },
    ];

    setDepositedUser(numberOfDepositedUser);
  }, [fetchedHomeData]);

  return (
    <Fragment>
      <>
        <HomeHeader>
          <TitleWrapper>
            <Paragraph>Hi Riya! You’re prepping for</Paragraph>
            <Heading>STOCKSY DASHBOARD</Heading>
          </TitleWrapper>

          <DatePickerBox>
            <DatePicker label="From" date={startDate} setDate={setStartDate} />
            <DatePicker label="To" date={endDate} setDate={setEndDate} />
          </DatePickerBox>
        </HomeHeader>

        <ChartWrapper>
          <TotalUsersBox>
            <TotalUsersLabel>total users</TotalUsersLabel>
            <TotalUsersBody>
              <TotalUsers>{totalUsers}</TotalUsers>
            </TotalUsersBody>
          </TotalUsersBox>
        </ChartWrapper>

        <UsersDetailsWrapper>
          <FunnelChart
            data={userMatrixData}
            dimension={{ width: 400, height: 500 }}
            head="User Matrix"
          />
          <FunnelChart
            data={depositedUser}
            dimension={{ width: 400, height: 500 }}
            head="Number Of Deposited User"
            showPercentage={false}
          />
        </UsersDetailsWrapper>
      </>

      {/* {showCreate && (
        <SectionDiv4>
          <CreateChallenge></CreateChallenge>
        </SectionDiv4>
      )} */}
    </Fragment>
  );
}
