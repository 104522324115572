import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "../pages/login";
import Home from "../pages/home";
import CreateChallenge from "../pages/create-challenge";
import Withdrawal from "../pages/withdrawal";
import Challenges from "../pages/challenges/component";
import UpChallenges from "../pages/upcoming-challenges";
import KYC from "../pages/kyc";
import Layout from "../components/compositions/layout";
import RouteAuth from "../helper/utils/route-auth";
import NotFoundPage from "../pages/404-page/component";

export default function Navigation() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/login"
          element={
            <RouteAuth isLoginPage={true}>
              <Login />
            </RouteAuth>
          }
        />
        <Route path="/" element={<Layout />}>
          <Route element={<RouteAuth isLoginPage={false} />}>
            <Route path="" element={<Home />} />
            <Route path="createChallenge" element={<CreateChallenge />} />
            <Route path="withdrawal" element={<Withdrawal />} />
            <Route path="challenges" element={<Challenges />} />
            <Route path="upChallenges" element={<UpChallenges />} />
            <Route path="kyc" element={<KYC />} />
          </Route>
        </Route>

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}
