import React, { Fragment, useState } from "react";
import { useFetch } from "../../helper/hooks/use-fetch";
import { Table } from "../../components/compositions/table";
import { dateFormatter } from "../../helper/utils/date-formatter";
import { Dropdown } from "../../components/elements/dropdown";
import { useDispatch, useSelector } from "react-redux";
import { fetchKycReducer } from "./fetcher";
import { Heading, DropdownContainer } from "./styles";
import { Pagination } from "../../components/elements/custom-pagination";
import { ArrowDropDown, ArrowDropUp, UnfoldMore } from "@mui/icons-material";

export function KycDetails(props) {
  // states
  const [clicked, setClicked] = useState(null);
  const [dataForPopup, setDataForPopup] = useState({});
  const [dropdownValue, setDropdownValue] = useState(
    "/admin/kyc/PENDING?limit=30&skip=0"
  );
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const [apiHandler] = useFetch();
  const [query, setQuery] = useState({
    sort_by: "created_on",
    sort_order: "desc",
  });
  const currentPageItemCount = useSelector((state) => state.fetchKyc.fetchKyc);

  const queryString = new URLSearchParams(query).toString();
  const pageLimit = 30;

  // table column names
  const columns = [
    {
      colName: "Name",
      icon: <UnfoldMore />,
      iconDown: <ArrowDropDown />,
      iconUp: <ArrowDropUp />,
      isSortable: true,
      sort_by: "name",
    },
    {
      colName: "DOB",
      icon: <UnfoldMore />,
      iconDown: <ArrowDropDown />,
      iconUp: <ArrowDropUp />,
      isSortable: true,
      sort_by: "date_of_birth",
    },
    {
      colName: "Adhar",
      icon: "",
    },
    {
      colName: "Pan",
      icon: "",
    },
    {
      colName: "Status",
      icon: "",
    },
    {
      colName: "Date",
      icon: <UnfoldMore />,
      iconDown: <ArrowDropDown />,
      iconUp: <ArrowDropUp />,
      isSortable: true,
      sort_by: "created_on",
    },
  ];

  const dropdownOtions = [
    {
      label: "PENDING",
      value: `/admin/kyc/PENDING?limit=${pageLimit}&skip=0`,
    },
    {
      label: "APPROVED",
      value: `/admin/kyc/APPROVED?limit=${pageLimit}&skip=0`,
    },
    {
      label: "REJECTED",
      value: `/admin/kyc/REJECTED?limit=${pageLimit}&skip=0`,
    },
  ];

  // this function is passed to table to get desired row's data and pass desired data to popup
  const populatePopupData = async (selectedRowOfKycTable) => {
    const transformedPopupData = {
      ...selectedRowOfKycTable.additionalData,
      popupImageArray: [
        {
          label: "Address Proof Front",
          value: await getUserImage(
            selectedRowOfKycTable.additionalData.addOn.addressProofFront
          ),
        },
        {
          label: "Address Proof Back",
          value: await getUserImage(
            selectedRowOfKycTable.additionalData.addOn.addressProofBack
          ),
        },
        {
          label: "identity Proof",
          value: await getUserImage(
            selectedRowOfKycTable.additionalData.addOn.identityProof
          ),
        },
      ],
    };
    setDataForPopup(transformedPopupData);
  };

  const getTableDataFunction = (kycData) => {
    // converting kyc data to rowWise data
    const singleLineData = (kycData || []).map((singleNode) => {
      // destructuring data
      const {
        details: {
          legal_name,
          aadhar_number,
          pan_number,
          identity_proof,
          address_proof_front,
          address_proof_back,
        },
        date_of_birth,
        created_on,
        status,
        name,
        phone,
        is_profile_not_complete,
        id,
      } = singleNode;

      return {
        rowData: [
          { Name: name },
          { DOB: date_of_birth.substring(0, 10) },
          { Adhar: aadhar_number },
          { Pan: pan_number },
          { Status: status },
          { Date: dateFormatter(created_on) },
        ],
        additionalData: {
          popupData: [
            {
              label: "Reg Name",
              value: name,
              icon: "",
            },
            {
              label: "Legal Name",
              value: legal_name,
              icon: "",
            },
            {
              label: "Mobile No",
              value: phone,
              icon: "",
            },

            {
              label: "DOB",
              value: date_of_birth.substring(0, 10),
              icon: "",
            },
            {
              label: "Aadhar Card",
              value: aadhar_number,
              icon: "",
            },
            {
              label: "Date",
              value: created_on.substring(0, 10),
              icon: "",
            },
            {
              label: "Is Approved",
              value: is_profile_not_complete,
              icon: "",
            },
            {
              label: "Pan Card",
              value: pan_number,
              icon: "",
            },
          ],
          addOn: {
            identityProof: identity_proof,
            addressProofFront: address_proof_front,
            addressProofBack: address_proof_back,
            userId: id,
          },
        },
      };
    });

    const tableData = {
      singleLineData: [],
      multiLineData: [],
    };

    if (singleLineData?.length > 0) {
      tableData.singleLineData = singleLineData;
      return tableData;
    } else {
      return tableData;
    }
  };

  const handleKYCStatus = async (e, kycStatus, userId, rejectionReason) => {
    e.preventDefault();
    try {
      const { data } = await apiHandler({
        method: "post",
        relativePath: "/admin/approve/kyc",
        payload: {
          user_id: userId,
          kyc_status: kycStatus,
          content: rejectionReason,
        },
        withCredentials: true,
      });

      dispatch(fetchKycReducer(dropdownValue));
    } catch (err) {
      console.log("error in kyc", err?.response?.statusText);
    }
  };

  const getUserImage = async (identificationaCardImage) => {
    try {
      const imagePath = identificationaCardImage;

      const { data: imgData, status } = await apiHandler({
        method: "GET",
        relativePath: `/admin/file?path=${imagePath}`,
        withCredentials: true,
      });
      if (status === 200) {
        const imageData = `data:image/png;base64,${imgData}`;

        return imageData;
      } else {
        console.error(`Failed to fetch  card image`);
        return null;
      }
    } catch (err) {
      console.log("err", err?.response?.statusText);
      return null;
    }
  };

  const handleChange = (e) => {
    setDropdownValue(e.target.value);
    setCurrentPage(1);
    dispatch(fetchKycReducer(e.target.value));
  };

  // we are defining states in table, so to get the data from table this function is used
  const callbackFunctionForTable = (queryObject) => {
    setQuery(queryObject);
  };

  // this function is used for Column click sorting
  function tableSortFunction(sort_by, sort_order) {
    const skipValue = (currentPage - 1) * pageLimit;

    const _ = {
      sort_by,
      sort_order,
    };

    const queryParams = new URLSearchParams(_).toString();

    fetchCurrentPageDataFunction(pageLimit, skipValue, queryParams);
  }

  const fetchCurrentPageDataFunction = (
    pageLimit,
    skipValue,
    queryParams = queryString
  ) => {
    if (dropdownValue === `/admin/kyc/PENDING?limit=${pageLimit}&skip=0`) {
      dispatch(
        fetchKycReducer(
          `/admin/kyc/PENDING?limit=${pageLimit}&skip=${skipValue}&${queryParams}`
        )
      );
    } else if (
      dropdownValue === `/admin/kyc/APPROVED?limit=${pageLimit}&skip=0`
    ) {
      dispatch(
        fetchKycReducer(
          `/admin/kyc/APPROVED?limit=${pageLimit}&skip=${skipValue}&${queryParams}`
        )
      );
    }
    if (dropdownValue === `/admin/kyc/REJECTED?limit=${pageLimit}&skip=0`) {
      dispatch(
        fetchKycReducer(
          `/admin/kyc/REJECTED?limit=${pageLimit}&skip=${skipValue}&${queryParams}`
        )
      );
    }
  };

  const nextPageHandler = () => {
    setCurrentPage((prev) => prev + 1);
    const pageNumber = currentPage + 1;
    const skipValue = (pageNumber - 1) * pageLimit;

    fetchCurrentPageDataFunction(pageLimit, skipValue);
  };

  const previousPageHandler = () => {
    setCurrentPage((prev) => prev - 1);
    const pageNumber = currentPage - 1;
    const skipValue = (pageNumber - 1) * pageLimit;
    fetchCurrentPageDataFunction(pageLimit, skipValue);
  };

  return (
    <Fragment>
      <Heading>
        <h1>KYC Details</h1>
        <p>Complete your kyc and start playing contest</p>
      </Heading>

      <DropdownContainer>
        <Dropdown
          value={dropdownValue}
          options={dropdownOtions}
          handleChange={handleChange}
        />
      </DropdownContainer>

      <Table.SingleLine
        headers={columns}
        hasPopupEnabled={true}
        tableData={getTableDataFunction(props?.fetchedData?.fetchKyc)}
        additionalData={dataForPopup}
        tableClickHandler={populatePopupData}
        childClickHandler={handleKYCStatus}
        clicked={clicked}
        setClicked={setClicked}
        callbackFunctionForTable={callbackFunctionForTable}
        tableSortFunction={tableSortFunction}
      />

      <Pagination
        currentPage={currentPage}
        nextPageHandler={nextPageHandler}
        previousPageHandler={previousPageHandler}
        isNextPageIconDisabled={currentPageItemCount?.length < pageLimit}
      />
    </Fragment>
  );
}
