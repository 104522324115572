import React, { useState, useRef } from "react";
import I1 from "../../assets/images/brand.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFetch } from "../../helper/hooks/use-fetch";
import { setIsAuthenticate } from "../../redux/reducers/login";
import {
  Login,
  LoginBox,
  LoginForm,
  LoginHeading,
  LoginLogo,
  LoginWrapper,
  MobileNumber,
  OtpInput,
  SendOtp,
} from "./style";

function Component(props) {
  const [otp, setOtp] = useState(false);
  const [otpID, setOtpId] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const numRef = useRef();
  const otpRef = useRef();
  const [apiHandler] = useFetch();

  const sendOtpHandler = async (e) => {
    const num = numRef.current.value;

    try {
      const { data: fetchOtpHandlerData, status } = await apiHandler({
        method: "post",
        relativePath: "/auth/otp/send",
        payload: { user_id: num },
        withCredentials: true,
      });

      if (status === 200) {
        setOtp(true);
        setOtpId(fetchOtpHandlerData.otp_id);
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const validateOtpHandler = async (e) => {
    const otpNum = otpRef.current.value;
    try {
      const { data: fetchOtpValidateData, status } = await apiHandler({
        method: "post",
        relativePath: "/auth/otp/validate",
        payload: {
          otp_id: otpID,
          otp: otpNum,
        },
        withCredentials: true,
      });

      if (status === 200) {
        console.log("fetchOtpValidateData", fetchOtpValidateData);
        dispatch(setIsAuthenticate(true));
        navigate("/dashboard");
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <>
      <LoginWrapper>
        <LoginBox>
          <LoginLogo src={I1} />

          <LoginForm onSubmit={(e) => e.preventDefault()}>
            {otp ? (
              <LoginHeading>Verify Phone</LoginHeading>
            ) : (
              <LoginHeading>Phone Number</LoginHeading>
            )}

            {!otp ? (
              <MobileNumber
                type="tel"
                id="phone"
                name="phone"
                placeholder="number"
                ref={numRef}
                pattern="[0-9]{10}"
                required
              />
            ) : (
              <OtpInput
                type="number"
                id="phone"
                name="phone"
                placeholder="enter 'otp'"
                ref={otpRef}
                required
              />
            )}

            {!otp && (
              <SendOtp
                variations={{ typeStyle: "success" }}
                label="Send Otp"
                onClickHandler={sendOtpHandler}
              />
            )}
            {otp && (
              <Login
                variations={{ typeStyle: "success" }}
                label="Login"
                onClickHandler={validateOtpHandler}
              />
            )}
          </LoginForm>
        </LoginBox>
      </LoginWrapper>

      {/* <DivTag>
        <div>
          <Form onSubmit={handler}>
            <FormP>Learn and Earn</FormP>
            {!otp && (
              <FormDiv>
                <Input
                  type="tel"
                  id="phone"
                  name="phone"
                  placeholder="number"
                  ref={numRef}
                  pattern="[0-9]{10}"
                  required
                />
              </FormDiv>
            )}
            {otp && (
              <FormDiv>
                <Input
                  type="number"
                  id="phone"
                  name="phone"
                  placeholder="enter 'otp'"
                  ref={otpRef}
                  required
                />
              </FormDiv>
            )}
            {!otp && <Button type="submit">Send OTP</Button>}
            {otp && <Button type="submit">Verify OTP</Button>}
          </Form>
        </div>
      </DivTag> */}
    </>
  );
}
export default Component;
