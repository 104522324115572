import React from "react";
import { Loader } from "./style";

function LoaderSpinner({ className, variations }) {
  return <Loader className={className} variations={variations}></Loader>;
}

Loader.defaultProps = {
  variations: {
    typeStyle: "primary",
  },
};

export default LoaderSpinner;
