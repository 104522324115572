import { Plugin } from "../../helper/plugins/redux-core-plugin";
//STEP: 1 - Create Fetcher

export const { fetcher: fetchTotalUsersReducer, fetchTotalUsersCount } = Plugin(
  "fetchTotalUsersCount",
  {
    method: "GET",
    apiEndpoint: `/admin/count_user?start_time=2022-04-08T06:58:05.556Z&end_time=2023-04-08T06:58:05.556Z&all_user=true`,
    withCredentials: true,
  }
);

export const { fetcher: fetchTotalSignupCountReducer, fetchTotalSignupCount } =
  Plugin("fetchTotalSignupCount", {
    method: "GET",
    apiEndpoint: `/admin/count_user?start_time=2022-04-08T06:58:05.556Z&end_time=2023-04-08T06:58:05.556Z&all_user=false`,
    withCredentials: true,
  });

export const {
  fetcher: fetchTotalGamePlayedReducer,
  fetchTotalGamePlayedCount,
} = Plugin("fetchTotalGamePlayedCount", {
  method: "GET",
  apiEndpoint: `/admin/user_join_contest?start_time=2022-04-08T06:58:05.556Z&end_time=2023-04-08T06:58:05.556Z&all_user=false`,
  withCredentials: true,
});

export const {
  fetcher: fetchFirstDepositUserCountReducer,
  fetchFirstDepositUserCount,
} = Plugin("fetchFirstDepositUserCount", {
  method: "GET",
  apiEndpoint: `/admin/user_deposit_count?start_time=2022-04-08T06:58:05.556Z&end_time=2023-04-08T06:58:05.556Z&min_count=1`,
  withCredentials: true,
});

export const {
  fetcher: fetchThirdDepositUserCountReducer,
  fetchThirdDepositUserCount,
} = Plugin("fetchThirdDepositUserCount", {
  method: "GET",
  apiEndpoint: `/admin/user_deposit_count?start_time=2022-04-08T06:58:05.556Z&end_time=2023-04-08T06:58:05.556Z&min_count=3`,
  withCredentials: true,
});

export const {
  fetcher: fetchFifthDepositUserCountReducer,
  fetchFifthDepositUserCount,
} = Plugin("fetchFifthDepositUserCount", {
  method: "GET",
  apiEndpoint: `/admin/user_deposit_count?start_time=2022-04-08T06:58:05.556Z&end_time=2023-04-08T06:58:05.556Z&min_count=5`,
  withCredentials: true,
});
