import React, { Fragment, useState } from "react";
import { Table } from "../../components/compositions/table";
import { useFetch } from "../../helper/hooks/use-fetch";
import { Dropdown } from "../../components/elements/dropdown";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "../../components/elements/custom-pagination";
import {
  fetchUpcomingChallengesReducer,
  fetchUpcomingContestTemplateReducer,
} from "./fetcher";
import {
  DropdownContainer,
  Wrapper,
  CreateChallangeLink,
  CreateChallangeButton,
} from "./styles";

export function UpcomingChallenges(props) {
  const [dropdownValue, setDropdownValue] = useState(
    "/challenge?limit=30&skip=0&status=NEW"
  );
  const [clicked, setClicked] = useState(null);
  const [dataForSlider, setDataForSlider] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [apiHandler] = useFetch();
  const dispatch = useDispatch();
  const currentPageItemCount = useSelector(
    (state) => state.fetchUpcomingChallenges.fetchUpcomingChallenges
  );

  const pageLimit = 30;

  const columns = [
    {
      colName: "Start/End",
      icon: "",
    },
    {
      colName: "Result/Total",
      icon: "",
    },
    {
      colName: "Maximum/Minimum",
      icon: "",
    },
    {
      colName: "Type/Maximum Pool",
      icon: "",
    },
    {
      colName: "Name/Status",
      icon: "",
    },
  ];

  const dropdownOtions = [
    {
      label: "NEW",
      value: `/challenge?limit=${pageLimit}&skip=0&status=NEW`,
    },
    {
      label: "ONGOING",
      value: `/challenge?limit=${pageLimit}&skip=0&status=ONGOING`,
    },
    {
      label: "COMPLETED",
      value: `/challenge?limit=${pageLimit}&skip=0&status=COMPLETED`,
    },
  ];

  const contestTemplate = props?.fetchedData?.fetchUpcomingContestTemplate;

  // for create contest dropdown selection
  const populateDropdownOptionsData = (contestTemplate) => {
    const dropdownOptions = (contestTemplate || []).map((contest) => {
      const { name, id } = contest;
      return {
        id,
        name,
        label: name,
        value: id,
      };
    });
    return dropdownOptions;
  };

  const dropdownOptions = populateDropdownOptionsData(contestTemplate);

  const getTableDataFunction = (challangesData) => {
    const multiLineData = (challangesData || []).map((singleNode) => {
      // destructuring data

      const {
        id,
        start_timestamp,
        end_timestamp,
        result_timestamp,
        name,
        type,
        status,
        max_pool,
        details: { total_players, max_player_from_team, min_player_from_team },
      } = singleNode;

      return {
        rowData: [
          {
            first: {
              label: "Start Time",
              value: start_timestamp,
              icon: "",
            },
            second: {
              label: "End Time",
              value: end_timestamp,
              icon: "",
            },
          },
          {
            first: {
              label: "Result Time",
              value: result_timestamp,
              icon: "",
            },
            second: {
              label: "Total Players",
              value: total_players,
              icon: "",
            },
          },
          {
            first: {
              label: "Maximum Player",
              value: max_player_from_team,
              icon: "",
            },
            second: {
              label: "Minimum Player",
              value: min_player_from_team,
              icon: "",
            },
          },
          {
            first: {
              label: "Type",
              value: type,
              icon: "",
            },
            second: {
              label: "Maximum Pool",
              value: max_pool,
              icon: "",
            },
          },
          {
            first: {
              label: "Challange Name",
              value: name,
              icon: "",
            },
            second: {
              label: "Status",
              value: status,
              icon: "",
            },
          },
        ],
        additionalData: {
          status,
          challangeId: id,
          sliderHeaders: [
            "Contest",
            "Variant",
            "Win %",
            "Prize Pool",
            "Spots",
            "Entry",
          ],
        },
      };
    });

    const tableData = {
      singleLineData: [],
      multiLineData: [],
    };

    if (multiLineData?.length) {
      tableData.multiLineData = multiLineData;
      return tableData;
    } else {
      return tableData;
    }
  };

  const getContestListOfChallangeById = async (challangeId) => {
    const { data } = await apiHandler({
      method: "GET",
      relativePath: `/challenge/${challangeId}/contests`,
      withCredentials: true,
    });

    console.log(data);
    return data;
  };

  const populateSliderData = async (selectedRowOfChallangesTable) => {
    const transformedSliderData = {
      ...selectedRowOfChallangesTable.additionalData,
      dropdownOptions,
      contestList: await getContestListOfChallangeById(
        selectedRowOfChallangesTable.additionalData.challangeId
      ),
    };

    setDataForSlider(transformedSliderData);
  };

  const createContestHandler = async (payloadData) => {
    try {
      const { data } = await apiHandler({
        method: "POST",
        relativePath: `/admin/contest`,
        withCredentials: true,
        payload: payloadData,
      });

      setClicked(null);
      dispatch(fetchUpcomingContestTemplateReducer());
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleDropdownClick = (e) => {
    setDropdownValue(e.target.value);
    setCurrentPage(1);
    dispatch(fetchUpcomingChallengesReducer(e.target.value));
  };

  // per page data fetch function
  const fetchCurrentPageDataFunction = (pageLimit, skipValue) => {
    if (dropdownValue === `/challenge?limit=${pageLimit}&skip=0&status=NEW`) {
      dispatch(
        fetchUpcomingChallengesReducer(
          `/challenge?limit=${pageLimit}&skip=${skipValue}&status=NEW`
        )
      );
    } else if (
      dropdownValue === `/challenge?limit=${pageLimit}&skip=0&status=ONGOING`
    ) {
      dispatch(
        fetchUpcomingChallengesReducer(
          `/challenge?limit=${pageLimit}&skip=${skipValue}&status=ONGOING`
        )
      );
    } else if (
      dropdownValue === `/challenge?limit=${pageLimit}&skip=0&status=COMPLETED`
    ) {
      dispatch(
        fetchUpcomingChallengesReducer(
          `/challenge?limit=${pageLimit}&skip=${skipValue}&status=COMPLETED`
        )
      );
    }
  };

  const nextPageHandler = () => {
    setCurrentPage((prev) => prev + 1);
    // state is not changed yet so we are changing it manually inside
    const pageNumber = currentPage + 1;
    const skipValue = (pageNumber - 1) * pageLimit;
    fetchCurrentPageDataFunction(pageLimit, skipValue);
  };

  const previousPageHandler = () => {
    setCurrentPage((prev) => prev - 1);
    // state is not changed yet so we are changing it manually inside
    const pageNumber = currentPage - 1;
    const skipValue = (pageNumber - 1) * pageLimit;
    fetchCurrentPageDataFunction(pageLimit, skipValue);
  };

  return (
    <Fragment>
      <Wrapper>
        <DropdownContainer>
          <Dropdown
            value={dropdownValue}
            options={dropdownOtions}
            handleChange={handleDropdownClick}
          />
        </DropdownContainer>

        <CreateChallangeButton
          variations={{ typeStyle: "success" }}
          label={
            <CreateChallangeLink to="/createChallenge">
              Create Challange
            </CreateChallangeLink>
          }
        />
      </Wrapper>

      <Table.MultiLine
        headers={columns}
        tableClickHandler={populateSliderData}
        additionalData={dataForSlider}
        clicked={clicked}
        setClicked={setClicked}
        hasSliderEnabled={true}
        tableData={getTableDataFunction(
          props?.fetchedData?.fetchUpcomingChallenges
        )}
        childClickHandler={[createContestHandler]}
      />

      <Pagination
        currentPage={currentPage}
        nextPageHandler={nextPageHandler}
        previousPageHandler={previousPageHandler}
        isNextPageIconDisabled={currentPageItemCount?.length < pageLimit}
      />
    </Fragment>
  );
}
