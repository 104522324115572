import React, { Fragment, useState, useRef } from "react";
import { useFetch } from "../../helper/hooks/use-fetch";

import {
  FormInputDate,
  Trackerlabel,
  Trackerselect,
  Input,
  CreateChallengeButton,
  PreviewButton,
  Wrapper,
  CreateContestWrapper,
  ContestHeading,
  InputWrapper,
  Form,
} from "./styles";
import { useNavigate } from "react-router";

export function CreateChallenge(props) {
  // states
  const [value, setValue] = useState("");
  const contestName = useRef();
  const [selectedContestType, setSelectedContestType] = useState();
  const [selectedTeam, setSelectedTeam] = useState([]);
  const challengeDate = useRef();
  const [seletedContestTemplate, setSelectedContestTemplate] = useState();
  const [selectedChallengeTemplate, setSelectedChallengeTemplate] = useState();
  const [apiHandler] = useFetch();
  const navigate = useNavigate();

  const dateHandler = (e) => {
    setValue(e.target.value);
  };
  const selectContestTypeHandler = (e) => {
    setSelectedContestType(e.target.value);
  };
  const selectTeamHandler = (e) => {
    setSelectedTeam([...selectedTeam, e.target.value]);
  };
  const selectContestTemplateHandler = (e) => {
    setSelectedContestTemplate(e.target.value);
  };
  const setChallengeTemplateHandler = (e) => {
    setSelectedChallengeTemplate(e.target.value);
  };

  const today = new Date().toISOString().split("T")[0];

  const createNewChallengeHandler = async (e) => {
    e.preventDefault();

    try {
      const { data: createdChallengeData, status } = await apiHandler({
        method: "post",
        relativePath: "/admin/challenge/complete",
        payload: {
          name: contestName.current.value,
          type: selectedContestType,
          teams: selectedTeam,
          challenge_date: challengeDate.current.value,
          contest_templates: [seletedContestTemplate],
          challenge_template: selectedChallengeTemplate,
        },
        withCredentials: true,
      });

      if (status === 200) {
        navigate("/upChallenges");
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  return (
    <Fragment>
      <Wrapper>
        <CreateContestWrapper>
          <ContestHeading>Create Challenge</ContestHeading>
          <Form onSubmit={createNewChallengeHandler}>
            <InputWrapper>
              <Trackerlabel>Challenge Name:</Trackerlabel>
              <Input ref={contestName} placeholder="name" required></Input>
            </InputWrapper>

            <InputWrapper>
              <Trackerlabel>Type:</Trackerlabel>
              <Trackerselect
                id="type"
                name="type"
                onChange={selectContestTypeHandler}
                value={selectedContestType}
                required
              >
                <option>Select Type</option>
                {props?.fetchedData?.fetchGameType.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </Trackerselect>
            </InputWrapper>

            <InputWrapper>
              <Trackerlabel>Team 1:</Trackerlabel>
              <Trackerselect
                id="team1"
                name="Team"
                onChange={selectTeamHandler}
                value={selectedTeam[0]}
                required
              >
                <option>Select Team</option>
                {props?.fetchedData?.fetchTeam.map((teamDetails) => (
                  <option
                    key={`team1-${teamDetails?.id}`}
                    value={teamDetails?.id}
                  >
                    {teamDetails?.name}
                  </option>
                ))}
              </Trackerselect>
            </InputWrapper>

            <InputWrapper>
              <Trackerlabel>Team 2:</Trackerlabel>
              <Trackerselect
                id="team2"
                name="Team"
                onChange={selectTeamHandler}
                value={selectedTeam[1]}
                required
              >
                <option>Select Team</option>
                {props?.fetchedData?.fetchTeam.map((teamDetails) => (
                  <option
                    key={`team2-${teamDetails?.id}`}
                    value={teamDetails?.id}
                  >
                    {teamDetails?.name}
                  </option>
                ))}
              </Trackerselect>
            </InputWrapper>

            <InputWrapper>
              <Trackerlabel>Contest Date:</Trackerlabel>
              <FormInputDate
                type="date"
                defaultValue={value}
                onChange={dateHandler}
                ref={challengeDate}
                min={today}
                required
              ></FormInputDate>
            </InputWrapper>

            <InputWrapper>
              <Trackerlabel>Contest Template:</Trackerlabel>
              <Trackerselect
                id="contest"
                name="contest"
                onChange={selectContestTemplateHandler}
                value={seletedContestTemplate}
                required
              >
                <option>Select Contest Template</option>
                {props?.fetchedData?.fetchContestTemplate.map((e) => (
                  <option key={e?.id} value={e?.id}>
                    {e?.name}
                  </option>
                ))}
              </Trackerselect>
            </InputWrapper>

            <InputWrapper>
              <Trackerlabel>Challenge Template:</Trackerlabel>
              <Trackerselect
                id="challenge"
                name="challenge"
                onChange={setChallengeTemplateHandler}
                value={selectedChallengeTemplate}
                required
              >
                <option>Select Challenge Template</option>
                {props?.fetchedData?.fetchChallengeTemplate.map((challenge) => (
                  <option key={challenge?.id} value={challenge?.id}>
                    {challenge?.name}
                  </option>
                ))}
              </Trackerselect>
            </InputWrapper>

            <CreateChallengeButton label="Create Challenge" />

            <PreviewButton label="Preview" isDisbaled={true} />
          </Form>
        </CreateContestWrapper>
      </Wrapper>
    </Fragment>
  );
}
