import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "./redux/store";
import Routes from "./routes";
import reportWebVitals from "./reportWebVitals";
import RoutesWrapper from "./components/compositions/routes-wrapper/component";
import "./index.css";
import ErrorBoundary from "./helper/error-handling/error-boundary";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <RoutesWrapper>
      <ErrorBoundary>
        <Routes />
      </ErrorBoundary>
    </RoutesWrapper>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
