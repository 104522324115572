import React from "react";
import { Error, HomeButton, LinkToHome, Wrapper } from "./style";

function Component() {
  return (
    <Wrapper>
      <Error>Page Not Found!!!</Error>
      <HomeButton
        variations={{ typeStyle: "secondary" }}
        label={<LinkToHome to="/">Home</LinkToHome>}
      />
    </Wrapper>
  );
}

export default Component;
