import styled from "styled-components";

// table header styling

{
  /*************  Single-Line Table  **************/
}

const SectionDiv = styled.div`
  width: 85%;
  height: min-content;
  margin-left: 8rem;
`;

const TableHead = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  background-color: #e5e5e5;
  border-radius: 10px;

  box-shadow: 0px 4px 6px rgba(9, 30, 66, 0.3);
`;
const ColumnNameWrapper = styled.button`
  padding: 0.7rem 0rem;
  color: #9e9e9e;
  width: ${({ totalColumns }) => 100 / totalColumns}%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
  border: none;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
`;

const Name = styled.h3``;

const Icon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
`;

const TableBody = styled.div`
  color: #9e9e9e;
  width: 100%;
`;

const TableRowWrapper = styled.div`
  width: 100%;
  height: fit-content;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: 1rem 0;

  box-shadow: 0px 4px 6px rgba(9, 30, 66, 0.3);
`;

const TableRow = styled.div`
  display: flex;
  width: 100%;
  cursor: pointer;
`;

const ColumnData = styled.div`
  padding: 1rem 0rem;
  color: black;
  font-weight: 500;
  width: ${({ totalColumns }) => 100 / totalColumns}%;
  text-align: center;
`;

const Status = styled.p`
  color: ${(props) => (props.status === "Approved" ? "green" : "red")};
  font-size: 12px;
`;

{
  /*************  Multi-Line Table  **************/
}

const LabelWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-weight: 600;
  color: #313131;
`;

const Label = styled.span``;

const Value = styled.p`
  text-align: start;
  margin-left: 2.2rem;
  color: #5b5b5b;
`;
const CardItem = styled.div`
  font-size: 0.8rem;
  font-weight: 500;
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
`;

const Input = styled.input`
  margin: 0.5rem 0 0 2rem;
  padding: 0.4rem 0.2rem;
  width: 50%;
`;
const SectionDiv3 = styled.div`
  display: inline-block;
`;
const SectionForm = styled.form`
  display: ruby;
`;
const TableWrapper = styled.table`
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
  }

  ${({ isMultiline }) =>
    isMultiline &&
    `
      tr:nth-child(odd) {
        background-color: #f2f2f2;
      }
    `}
`;

const EmptyTableMessage = styled.p`
  font-size: 18px;
  color: #6a6a6a;
`;

export {
  SectionDiv,
  TableWrapper,
  TableHead,
  ColumnNameWrapper,
  Name,
  Icon,
  ColumnData,
  TableRow,
  TableBody,
  Status,
  CardItem,
  LabelWrapper,
  Label,
  Value,
  SectionDiv3,
  SectionForm,
  Input,
  TableRowWrapper,
  EmptyTableMessage,
};
