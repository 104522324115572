import Dashboard from "../../pages/dashboard/reducer";
import {
  fetchTotalUsersCount,
  fetchTotalSignupCount,
  fetchTotalGamePlayedCount,
  fetchFirstDepositUserCount,
  fetchThirdDepositUserCount,
  fetchFifthDepositUserCount,
} from "../../pages/home/fetcher";
import {
  fetchChallengeTemplate,
  fetchContestTemplate,
  fetchGameType,
  fetchTeam,
} from "../../pages/create-challenge/fetcher";
import {
  fetchUpcomingChallenges,
  fetchUpcomingContestTemplate,
} from "../../pages/upcoming-challenges/fetcher";
import { fetchKyc } from "../../pages/kyc/fetcher";
import { fetchWithdraw } from "../../pages/withdrawal/fetcher";
import SetLoaderSlice from "./loader";
import loginStatus from "./login";

// Step: 3 - Import extra reducer and export it here

export const allReducer = {
  // counter: Login,
  counter2: Dashboard,

  fetchTotalUsersCount,
  fetchTotalSignupCount,
  fetchTotalGamePlayedCount,
  fetchFirstDepositUserCount,
  fetchThirdDepositUserCount,
  fetchFifthDepositUserCount,

  fetchChallengeTemplate,
  fetchContestTemplate,
  fetchGameType,
  fetchTeam,

  fetchUpcomingChallenges,
  fetchUpcomingContestTemplate,
  fetchKyc,
  fetchWithdraw,

  SetLoader: SetLoaderSlice,
  loginStatus,
};
