import React from "react";
import { Option, Select } from "./style";

function Dropdown({ value, variations, options, handleChange }) {
  return (
    <>
      <Select variations={variations} value={value} onChange={handleChange}>
        {variations.typeStyle === "primary" &&
          options.map((option, index) => {
            return (
              <Option key={index} value={option.value}>
                {option.label}
              </Option>
            );
          })}
        {variations.typeStyle === "secondary" && (
          <>
            <Option value="" selected disabled>
              Select a Contest
            </Option>
            {options.map((option, index) => {
              return (
                <Option key={index} value={option.value}>
                  {option.label}
                </Option>
              );
            })}
          </>
        )}
      </Select>
    </>
  );
}

Dropdown.defaultProps = {
  variations: {
    typeStyle: "primary",
  },
};

export default Dropdown;
