import DatePicker from "react-date-picker";
import styled from "styled-components";

const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
`;

const DatePickerLabel = styled.span``;

const ModifiedDatePicker = styled(DatePicker)`
  .react-date-picker__wrapper {
    border-radius: 4px;
    padding: 2px;
  }

  .react-date-picker__inputGroup__input:focus-within {
    outline: none;
  }
`;

export { DatePickerWrapper, ModifiedDatePicker, DatePickerLabel };
