import styled from "styled-components";
import { Button } from "../../components/elements/button";

const LoginWrapper = styled.div`
  width: 100%;
  background: linear-gradient(252.44deg, #262d96 0%, #764af6 100%);
  height: 100vh;
  display: grid;
  // grid-template-columns: 50% 50%;
  grid-template-rows: 15% 78% 7%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 40rem;
  padding: 4rem 0;
  height: max-content;
  color: white;
  box-shadow: 7px 7px 20px 0px rgba(0, 0, 0, 0.1),
    4px 4px 5px 0px rgba(0, 0, 0, 0.1);
`;

const LoginLogo = styled.img`
  width: 10rem;
  height: auto;
  margin-bottom: 2rem;
`;
const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
`;

const LoginHeading = styled.h3`
  font-weight: 600;
`;

const MobileNumber = styled.input`
  padding: 0.7rem 0 0.7rem 0.7rem;
  width: 15rem;
  border-radius: 0.5rem;
  border: none;
  box-shadow: 0.5rem 0.1rem 0.5rem 0px #2d2d3083;
  box-decoration-break: clone;
`;

const OtpInput = styled(MobileNumber)``;

const SendOtp = styled(Button)`
  padding: 0.5rem 1rem;
  width: 15rem;
  border-radius: 10px;
  box-shadow: 0.5rem 0.1rem 0.5rem 0px #2d2d3083;
  box-decoration-break: clone;
`;

const Login = styled(SendOtp)``;

export {
  LoginWrapper,
  LoginBox,
  LoginLogo,
  LoginForm,
  LoginHeading,
  MobileNumber,
  OtpInput,
  SendOtp,
  Login,
};
