import React from "react";
import { Box, Error, Reload } from "./style";

function Fallback() {
  const realoadFunction = () => {
    window.location.reload();
  };
  return (
    <>
      <Box>
        <Error>Something Went Wrong!!!</Error>
        <Reload
          variations={{ typeStyle: "secondary" }}
          label="Reload"
          onClickHandler={realoadFunction}
        />
      </Box>
    </>
  );
}

export default Fallback;
