import { Table } from "./component";
import { asVariation } from "../../../helper/variations";

Table.SingleLine = asVariation(Table, "singleline", {
  typeStyle: "singleline",
});

Table.MultiLine = asVariation(Table, "multiline", {
  typeStyle: "multiline",
});

export { Table };
