// import { KycDetails } from "./kycDetails";
import { asConfiguredComponent } from "../../helper/configured-component/configured-component";
import { fetchWithdrawalReducer } from "./fetcher";
import { WithdrawalDetails } from "./component";

//STEP: 2 - Import fetcher reducer and pass it to asConfiguredComponent

const Component = asConfiguredComponent(
  [fetchWithdrawalReducer],
  WithdrawalDetails
);
export default Component;
