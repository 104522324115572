import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

function mapStateToProp(state, fetchers) {
  let fetcheddData = {};
  for (const fetcher of fetchers) {
    const getFetchData = state?.[fetcher.typePrefix];
    if (state[fetcher.typePrefix]) {
      fetcheddData = {
        ...fetcheddData,
        ...state?.[fetcher.typePrefix],
      };
    }
  }
  return fetcheddData;
}

export const asConfiguredComponent = (fetchers, Component) => {
  const Wrapper = () => {
    const dispath = useDispatch();
    const getFetchData = (state) => state;
    const storeData = useSelector(getFetchData);

    const fetchedData = mapStateToProp(storeData, fetchers);

    useEffect(() => {
      fetchers.forEach((fetcher) => {
        dispath(fetcher());
      });
    }, [dispath]);
    return <Component fetchedData={fetchedData} />;
  };
  return Wrapper;
};
