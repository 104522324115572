import styled from "styled-components";

const FunnelHeading = styled.h4`
  text-align: center;
  margin-bottom: 1rem;
`;

const SvgWrappingElement = styled.p`
  width: 100%;
  height: 100%;
`;

export { FunnelHeading, SvgWrappingElement };
