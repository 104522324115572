import styled from "styled-components";

const Heading = styled.div`
  margin-left: 8rem;
  margin-top: -3rem;
  margin-bottom: 4rem;
`;

const DropdownContainer = styled.div`
  margin-left: 8rem;
  margin-top: -3rem;
  margin-bottom: 2rem;
`;

export { Heading, DropdownContainer };
