import { Button, Dialog, DialogContent } from "@mui/material";
import React, { useState } from "react";
import NoImg from "../../../assets/images/no_img.png";
import {
  DataField,
  DialogImage,
  UserImg,
  TextArea,
  UserImageContainer,
  ImageLabel,
} from "./style";

function DetailsInPopup({
  detailsInPopupData,
  openPopupWindow,
  setPopupOpen,
  popupOnclickHandler,
}) {
  // states

  const [rejectionReason, setRejectionReason] = useState("");

  const {
    popupData,
    addOn: { userId },
    popupImageArray,
  } = detailsInPopupData;

  const handlePopupClose = () => {
    setPopupOpen(false);
    setRejectionReason("");
  };

  return (
    <>
      {detailsInPopupData && openPopupWindow && (
        <div style={{ zIndex: 10000 }}>
          <Dialog
            PaperProps={{ style: { width: "75%", maxWidth: "none" } }}
            open={detailsInPopupData !== null}
            onClose={handlePopupClose}
          >
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <DialogContent
                style={{ display: "flex", flexDirection: "column" }}
              >
                {popupData.map((item, index) => (
                  <DataField key={index}>
                    {item.label} : {item.value}
                  </DataField>
                ))}

                <Button
                  style={{
                    width: "22rem",
                    margin: "1.6rem",
                    padding: "0.8rem",
                  }}
                  variant="contained"
                  color="success"
                  onClick={(e) =>
                    popupOnclickHandler(e, "APPROVED", userId, rejectionReason)
                  }
                >
                  {" "}
                  Approve
                </Button>

                <TextArea
                  value={rejectionReason}
                  placeholder="Reason of rejection..."
                  rows="4"
                  cols="50"
                  onChange={(e) => setRejectionReason(e.target.value)}
                />

                <Button
                  style={{
                    width: "22rem",
                    margin: "1.6rem",
                    padding: "0.8rem",
                  }}
                  variant="contained"
                  color="error"
                  onClick={(e) =>
                    popupOnclickHandler(e, "REJECTED", userId, rejectionReason)
                  }
                >
                  Reject
                </Button>
              </DialogContent>

              <DialogImage>
                {popupImageArray.map((image, index) => (
                  <UserImageContainer>
                    <UserImg
                      key={index}
                      src={image.value || NoImg}
                      alt={`${image.label} Image`}
                    />
                    <ImageLabel>{image.label}</ImageLabel>
                  </UserImageContainer>
                ))}
              </DialogImage>
            </div>
          </Dialog>
        </div>
      )}
    </>
  );
}
DetailsInPopup.defaultProps = {
  variations: { typeStyle: "primary" },
};

export default DetailsInPopup;
