import styled from "styled-components";
import { Button } from "../../components/elements/button";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const Error = styled.h1`
  margin-top: 2rem;
`;

const HomeButton = styled(Button)`
  padding: 10px 20px;
`;

const LinkToHome = styled(Link)`
  text-decoration: none;
  font-size: 16px;
  color: white;
`;

export { Wrapper, Error, HomeButton, LinkToHome };
