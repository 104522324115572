import React, { useState, useEffect, Fragment } from "react";
import { Snackbar, Alert } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { removeFromResponseState } from "../../../redux/reducers/loader";

export default function ConsecutiveSnackbars({ loadingState }) {
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);
  const [snackPack, setSnackPack] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setSnackPack([...loadingState]);
  }, [loadingState]);

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      setMessageInfo({ ...snackPack[0], key: new Date().getTime() });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    }
  }, [snackPack, messageInfo, open]);

  useEffect(() => {
    if (open === false) dispatch(removeFromResponseState());
  }, [open]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  return (
    <div>
      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        open={open}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleClose}
        TransitionProps={{ onExited: handleExited }}
        action={
          <Fragment>
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Fragment>
        }
      >
        <Alert
          onClose={handleClose}
          severity={`${messageInfo?.isError ? "error" : "success"}`}
          sx={{ width: "100%" }}
        >
          {messageInfo ? messageInfo?.message : undefined}
        </Alert>
      </Snackbar>
    </div>
  );
}
