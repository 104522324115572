import { useDispatch } from "react-redux";
import httpFetch from "../utils/http-fetch";
import { setIsLoading, addToResponseState } from "../../redux/reducers/loader";

export function useFetch() {
  const dispatch = useDispatch();
  const apiHandler = async ({
    method = "GET",
    relativePath = "",
    withCredentials = true,
    payload = {},
  }) => {
    try {
      dispatch(setIsLoading(true));
      const { data, status } = await httpFetch({
        method,
        relativePath,
        withCredentials,
        payload,
      });

      dispatch(setIsLoading(false));

      // only for post request set success true
      if (method !== "GET") {
        dispatch(
          addToResponseState({
            isError: false,
            isSuccess: true,
            message: "success !!!",
          })
        );
      }
      return { data, status };
    } catch (err) {
      dispatch(setIsLoading(false));

      dispatch(
        addToResponseState({
          isError: true,
          isSuccess: false,
          message: err?.response?.data
            ? err?.response?.data?.errors?.[0]?.msg
            : err?.response?.statusText,
        })
      );

      throw err;
    }
  };
  return [apiHandler];
}
