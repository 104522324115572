import styles from "styled-components";

const SectionDiv = styles.div`
  width: 100%;
  height:100vh;
  margin-left: 17rem;
`;
const SectionCard = styles.div`
width:80%;
background-color:white;
border-radius: 0.5rem;
box-shadow: 0px 10px 19px rgba(9, 30, 66, 0.12);
`;
const CardHead = styles.h2`
font-size:1.7rem;
margin:2rem 0;
text-align:center;
background-image: linear-gradient(115deg, #999 0%, #000 40%, transparent 74%);
color: white;
`;
const CardHead2 = styles.h3`
font-size:1.2rem;
margin:1rem 0 1rem 2rem;
`;
const CardItems = styles.div`
display:flex;
gap:2rem;
`;
const CardItemsIn = styles.div`
margin-left:2rem;
`;
const CardItem = styles.p`
font-size:0.8rem;
font-weight:500;
padding:0.5rem 0;
display:flex;
gap:.6rem;
`;
const SectionButton = styles.button`
border:0.1rem solid #999;
border-radius:.3rem;
padding:0.3rem 0.7rem;
font-weight:600;
color:#333;
margin:4rem 6rem;
background-color:#764af6;
font-size:0.9rem;
transition: all 0.2s;
&:hover{
color:white;
border:0.1rem solid #000;
cursor:pointer;
}
`;
const SectionButton2 = styles(SectionButton)`
margin:2rem 2.55rem;
`;
const SectionButton3 = styles(SectionButton2)`
display:flex;
align-items:center;
background-color:rgba(16, 155, 67, 0.756);
`;

const SectionCard2 = styles.div`
transition: visibility 0s, opacity 0.5s linear;
`;
const SectionLabel = styles.label`

margin:0.5rem 0 0 2rem;
font-weight:500;
`;
const SectionInput = styles.select`
margin:0.5rem 0 0 2rem;
padding:0.4rem 0.2rem;
width:30%;
`;
const SectionDiv3 = styles.div`
display:flex;
`;
const SectionForm = styles.form`
// display:flex;
margin-top:2rem;
`;


export {
    SectionDiv,
    SectionCard,
    CardHead,
    CardHead2,
    CardItems,
    CardItemsIn,
    CardItem,
    SectionButton,
    SectionButton2,
    SectionButton3,
    SectionCard2,
    SectionLabel,
    SectionInput,
    SectionDiv3,
    SectionForm,
}