import React, { Fragment } from "react";
import { CreateChallenge } from "./component";
import { asConfiguredComponent } from "../../helper/configured-component/configured-component";
import RouteAuth from "../../helper/utils/route-auth";
import {
  fetchGameTypeReducer,
  fetchContestTemplateReducer,
  fetchTeamReducer,
  fetchChallengeTemplateReducer,
} from "./fetcher";

//STEP: 2 - Import fetcher reducer and pass it to asConfiguredComponent

const Component = asConfiguredComponent(
  [
    fetchGameTypeReducer,
    fetchContestTemplateReducer,
    fetchTeamReducer,
    fetchChallengeTemplateReducer,
  ],
  CreateChallenge
);
export default Component;
