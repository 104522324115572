import { Button } from "./component";
import { asVariation } from "../../../helper/variations";

Button.Primary = asVariation(Button, "primary", {
  typeStyle: "primary",
});

Button.Secondary = asVariation(Button, "secondary", {
  typeStyle: "secondary",
});

Button.Success = asVariation(Button, "success", {
  typeStyle: "success",
});
Button.Table = asVariation(Button, "table", {
  typeStyle: "table",
});

export { Button };
