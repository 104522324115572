import React, { useState } from "react";
import styles from "styled-components";
import { getContests } from "./slice";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import GroupsIcon from "@mui/icons-material/Groups";
import { useDispatch, useSelector } from "react-redux";
// import { getChallengeTemplate } from "../CreateChallenge/slice";

import {
  SectionDiv,
    SectionCard,
    CardHead,
    CardHead2,
    CardItems,
    CardItemsIn,
    CardItem,
    SectionButton,
    SectionButton2,
    SectionButton3,
    SectionCard2,
    SectionLabel,
    SectionInput,
    SectionDiv3,
    SectionForm,
} from "./styles"


export default function Challenge() {
  const [show, showValue] = useState(true);
  const activeChallenges = useSelector(getContests);
  const challengeTemplate = useSelector(getContests);

  const showHandler = () => {
    showValue(!show);
  };
  console.log("activeChallenges", activeChallenges);
  return (
    <SectionDiv>
      {activeChallenges.map((challenge, i) => (
        <SectionCard key={i}>
          <CardHead>{challenge?.name}</CardHead>
          <CardHead2>win chance:{challenge?.win_chance}</CardHead2>
          <CardItems type={show}>
            <CardItemsIn>
              <CardItem>
                <MeetingRoomIcon></MeetingRoomIcon>entry-fees:
                {challenge?.entry_fees}
              </CardItem>
              <CardItem>
                <EmojiEventsIcon></EmojiEventsIcon>
                first-winner: {challenge?.first_winner}
              </CardItem>
              <CardItem>
                <GroupIcon></GroupIcon>
                current-spots: {challenge?.current_spots}
              </CardItem>
            </CardItemsIn>
            {/* <Border></Border> */}
            <div>
              <CardItem>
                <PersonIcon></PersonIcon>
                min-spots: {challenge?.min_spots}
              </CardItem>
              <CardItem>
                <GroupsIcon></GroupsIcon>
                total-spots: {challenge?.total_spots}
              </CardItem>
              <CardItem>
                <MilitaryTechIcon></MilitaryTechIcon>
                total-winnings: {challenge?.total_winnings}
              </CardItem>
            </div>
          </CardItems>
          <SectionDiv3>
            <SectionForm>
              <SectionLabel>Challenges</SectionLabel>
              <SectionInput>
                <option>Select Challenge </option>
                {challengeTemplate.map((challenge) => (
                  <option key={challenge?.id} value={challenge?.id}>
                    {challenge?.name}
                  </option>
                ))}
              </SectionInput>
            </SectionForm>
            <SectionButton onClick={showHandler}>Create Contest</SectionButton>
          </SectionDiv3>
        </SectionCard>
      ))}
    </SectionDiv>
  );
}
