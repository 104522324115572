const intlFormatter = Intl.DateTimeFormat("en-AU", {
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
});

const dateFormatter = (utcDate) => {
  const createdOnDate = new Date(utcDate);
  const newFormattedDate = intlFormatter.format(createdOnDate);
  return newFormattedDate;
};

export { intlFormatter, dateFormatter };
