import React from "react";
import {
  NextIcon,
  PageNumber,
  PageNumberWrapper,
  PaginationBox,
  PaginationButton,
  PreviousIcon,
  Wrapper,
} from "./style";

function Pagination({
  currentPage,
  nextPageHandler,
  previousPageHandler,
  isNextPageIconDisabled,
}) {
  return (
    <Wrapper>
      <PaginationBox>
        <PaginationButton
          label={<PreviousIcon disabled={currentPage <= 1} />}
          onClickHandler={previousPageHandler}
          isDisabled={currentPage <= 1}
        />
        <PageNumberWrapper>
          <PageNumber>{currentPage}</PageNumber>
        </PageNumberWrapper>

        <PaginationButton
          label={<NextIcon disabled={isNextPageIconDisabled} />}
          onClickHandler={nextPageHandler}
          isDisabled={isNextPageIconDisabled}
        />
      </PaginationBox>
    </Wrapper>
  );
}

Pagination.defaultProps = {
  variations: {
    typeStyle: "primary",
  },
};

export default Pagination;
