import React from "react";
import { ButtonWrapper, ButtonLabel } from "./styles";

export const Button = ({
  className,
  isDisabled,
  label,
  onClickHandler,
  variations,
  variationName,
}) => {
  return (
    <ButtonWrapper
      className={className}
      variations={variations}
      onClick={onClickHandler}
      disabled={isDisabled}
    >
      <ButtonLabel variations={variations}> {label} </ButtonLabel>
    </ButtonWrapper>
  );
};

Button.defaultProps = {
  isDisbaled: false,
  label: " Primary Button",
  variations: {
    typeStyle: "primary",
  },
};
