import React from "react";

import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router";

function RouteAuth({ isLoginPage, children }) {
  const authValue = useSelector((state) => state.loginStatus.isAuthenticate);

  if (isLoginPage) return authValue ? <Navigate to="/" replace /> : children;
  else return authValue ? <Outlet /> : <Navigate to="/login" replace />;
}

export default RouteAuth;
