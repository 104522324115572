import { createAsyncThunk } from "@reduxjs/toolkit";
import httpFetch from "../utils/http-fetch";
import { addToResponseState, setIsLoading } from "../../redux/reducers/loader";
import { setIsAuthenticate } from "../../redux/reducers/login";

export const configureAsyncThunk = (
  path,
  { method = "GET", apiEndpoint = "", withCredentials = true }
) => {
  const asyncFetcher = createAsyncThunk(
    path,
    async (relativePath = apiEndpoint, thunkApi) => {
      try {
        thunkApi.dispatch(setIsLoading(true));
        const { data } = await httpFetch({
          method,
          relativePath,
          withCredentials,
        });

        thunkApi.dispatch(setIsLoading(false));
        return data;
      } catch (err) {
        thunkApi.dispatch(setIsLoading(false));
        thunkApi.dispatch(
          addToResponseState({
            isError: true,
            isSuccess: false,
            message: err?.response?.data
              ? err?.response?.data?.errors?.[0]?.msg
              : err?.response?.statusText,
          })
        );
        if (err?.response?.status === 403 || err?.response?.status === 401) {
          thunkApi.dispatch(setIsAuthenticate(false));
        }
      }
    }
  );
  return asyncFetcher;
};
