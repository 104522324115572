import styled, { css } from "styled-components";
import { Button } from "../../elements/button";

const SliderWrapper = styled.div`
  width: 100%;
  background-color: #e5e5e5;
  border-radius: 0 0 10px 10px;
  ${({ active }) =>
    active
      ? css`
          height: auto;
          opacity: 1;
        `
      : css`
          height: 0;
          opacity: 0;
        `}
`;

const SliderDataWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 3rem;
`;

const SliderData = styled.div`
  width: 50%;
  padding: 0.5rem 1rem;
  display: flex;
  gap: 2rem;
  color: #686868;
`;

const SliderHeader = styled.h4`
  width: ${({ totalColumns }) => 100 / totalColumns}%;
  text-align: center;
  margin-bottom: 0.5rem;
  color: #6a6a6a;
`;

const SliderDataTag = styled.span`
  font-weight: 700;
`;

const SliderDataField = styled.p``;

const SliderAction = styled.form`
  width: 100%;
  display: flex;
  gap: 3rem;
  align-items: center;
  margin-top: 2rem;
`;

const MultilineSliderAction = styled(SliderAction)`
  display: ${({ status }) => {
    if (status === "NEW") return "flex";
    else return "none";
  }};
`;

const ButtonBox = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ActionButton = styled(Button)`
  font-size: 1rem;
  letter-spacing: 1px;
  padding: 6px 0;
  text-transform: uppercase;
`;

const InputField = styled.input`
  padding: 10px;
  height: fit-content;

  width: ${({ type }) => {
    if (type === "text") return "40%";
    else if (type === "number") return "20%";
  }};

  &:focus-visible {
    outline: none;
  }
`;

// secondary slider
const ContestWrapper = styled(SliderData)`
  width: 100%;
  justify-content: space-between;
  background-color: ${({ sequence }) => {
    if (sequence % 2) return "#e5e5e5";
    else return "white";
  }};
`;

const ContestData = styled.p`
  width: ${({ totalColumns }) => 100 / totalColumns}%;
  text-align: center;
`;

export {
  SliderWrapper,
  SliderDataWrapper,
  SliderHeader,
  SliderData,
  SliderDataTag,
  SliderDataField,
  SliderAction,
  MultilineSliderAction,
  InputField,
  ButtonBox,
  ActionButton,
  ContestWrapper,
  ContestData,
};
