import React, { Fragment, useState } from "react";
import { useFetch } from "../../helper/hooks/use-fetch";

import { DropdownContainer, Heading } from "./styles";
import { Table } from "../../components/compositions/table";
import { dateFormatter } from "../../helper/utils/date-formatter";
import { useDispatch, useSelector } from "react-redux";
import { fetchWithdrawalReducer } from "./fetcher";
import { Dropdown } from "../../components/elements/dropdown";
import { Pagination } from "../../components/elements/custom-pagination";
import { ArrowDropDown, ArrowDropUp, UnfoldMore } from "@mui/icons-material";

export function WithdrawalDetails(props) {
  // states
  const [dataForSlider, setDataForSlider] = useState({});
  const [clicked, setClicked] = useState(null);
  const [dropdownValue, setDropdownValue] = useState(
    `/admin/withdraw/transaction?limit=30&skip=0`
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [apiHandler] = useFetch();
  const dispatch = useDispatch();
  const [query, setQuery] = useState({
    sort_by: "created_on",
    sort_order: "desc",
  });

  const currentPageItemCount = useSelector(
    (state) => state.fetchWithdraw.fetchWithdraw
  );

  const queryString = new URLSearchParams(query).toString();
  const pageLimit = 30;

  // table column names

  const columns = [
    {
      colName: "Name",
      icon: <UnfoldMore />,
      iconDown: <ArrowDropDown />,
      iconUp: <ArrowDropUp />,
      isSortable: true,
      sort_by: "legal_name",
    },
    {
      colName: "Phone",
      icon: "",
    },
    {
      colName: "Amount",
      icon: "",
    },
    {
      colName: "Status",
      icon: "",
    },
    {
      colName: "Date",
      icon: <UnfoldMore />,
      iconDown: <ArrowDropDown />,
      iconUp: <ArrowDropUp />,
      isSortable: true,
      sort_by: "created_on",
    },
  ];

  const dropdownOtions = [
    {
      label: "ALL",
      value: `/admin/withdraw/transaction?limit=${pageLimit}&skip=0`,
    },
    {
      label: "NEW",
      value: `/admin/withdraw/transaction?status=NEW&limit=${pageLimit}&skip=0`,
    },
    {
      label: "PROCESSED",
      value: `/admin/withdraw/transaction?status=PROCESSED&limit=${pageLimit}&skip=0`,
    },
    {
      label: "REJECTED",
      value: `/admin/withdraw/transaction?status=REJECTED&limit=${pageLimit}&skip=0`,
    },
  ];

  const handleDropdownChange = (e) => {
    setDropdownValue(e.target.value);
    setCurrentPage(1);
    dispatch(fetchWithdrawalReducer(e.target.value));
  };

  // this function is passed to table to get desired row's data and pass desired data to slider
  const populateSliderData = async (selectedRowOfWithdrawTable) => {
    const transformedPopupData = {
      ...selectedRowOfWithdrawTable.additionalData,
    };
    setDataForSlider(transformedPopupData);
  };

  const getTableDataFunction = (withdrawalData) => {
    const singleLineData = (withdrawalData || []).map((singleNode) => {
      // destructuring data
      const {
        user: {
          details: {
            name,
            legal_name,
            account_number,
            residence,
            ifsc_code,
            upi,
          },
          phone,
        },
        amount,
        status,
        id,
        transaction_id,
        created_on,
      } = singleNode;

      // returning single rows data for table
      return {
        rowData: [
          { Name: legal_name },
          { Phone: phone },
          { Amount: amount },
          { Status: status },
          { Date: dateFormatter(created_on) },
        ],
        additionalData: {
          popupData: upi
            ? [
                {
                  label: "Payee Name",
                  value: name,
                  icon: "",
                },
                {
                  label: "Country",
                  value: residence,
                  icon: "",
                },
                {
                  label: "Account",
                  value: account_number,
                  icon: "",
                },
                {
                  label: "IFSC",
                  value: ifsc_code,
                  icon: "",
                },
                {
                  label: "UPI",
                  value: upi,
                  icon: "",
                },
              ]
            : [
                {
                  label: "Payee Name",
                  value: name,
                  icon: "",
                },
                {
                  label: "Country",
                  value: residence,
                  icon: "",
                },
                {
                  label: "Account",
                  value: account_number,
                  icon: "",
                },
                {
                  label: "IFSC",
                  value: ifsc_code,
                  icon: "",
                },
              ],

          sliderData: {
            id,
            transaction_id,
            name: legal_name,
            status,
            amount,
          },
        },
      };
    });

    // making table data
    const tableData = {
      singleLineData: [],
      multiLineData: [],
    };

    if (singleLineData?.length > 0) {
      tableData.singleLineData = singleLineData;
      return tableData;
    } else {
      return tableData;
    }
  };

  const proceedWithdrawal = async (payloadData) => {
    try {
      const { data } = await apiHandler({
        method: "POST",
        relativePath: `/admin/withdraw/approve`,
        withCredentials: true,
        payload: payloadData,
      });

      setClicked(null);
      dispatch(fetchWithdrawalReducer(dropdownValue));
    } catch (err) {
      console.log("err", err);
    }
  };

  const rejectWithdrawal = async (payloadData) => {
    try {
      const { data } = await apiHandler({
        method: "POST",
        relativePath: `/admin/withdraw/reject`,
        withCredentials: true,
        payload: payloadData,
      });

      setClicked(null);
      dispatch(fetchWithdrawalReducer(dropdownValue));
    } catch (err) {
      console.log("err", err);
    }
  };

  // we are defining states in table, so to get the data from table this function is used
  const callbackFunctionForTable = (queryParam) => {
    setQuery(queryParam);
  };

  // this function is used for Column click sorting
  function tableSortFunction(sort_by, sort_order) {
    const skipValue = (currentPage - 1) * pageLimit;

    const _ = {
      sort_by,
      sort_order,
    };
    const queryString = new URLSearchParams(_).toString();

    fetchCurrentPageDataFunction(pageLimit, skipValue, queryString);
  }

  const fetchCurrentPageDataFunction = (
    pageLimit,
    skipValue,
    urlQuery = queryString
  ) => {
    if (
      dropdownValue === `/admin/withdraw/transaction?limit=${pageLimit}&skip=0`
    ) {
      dispatch(
        fetchWithdrawalReducer(
          `/admin/withdraw/transaction?limit=${pageLimit}&skip=${skipValue}&${urlQuery}`
        )
      );
    } else if (
      dropdownValue ===
      `/admin/withdraw/transaction?status=NEW&limit=${pageLimit}&skip=0`
    ) {
      dispatch(
        fetchWithdrawalReducer(
          `/admin/withdraw/transaction?status=NEW&limit=${pageLimit}&skip=${skipValue}&${urlQuery}`
        )
      );
    } else if (
      dropdownValue ===
      `/admin/withdraw/transaction?status=PROCESSED&limit=${pageLimit}&skip=0`
    ) {
      dispatch(
        fetchWithdrawalReducer(
          `/admin/withdraw/transaction?status=PROCESSED&limit=${pageLimit}&skip=${skipValue}&${urlQuery}`
        )
      );
    }
    if (
      dropdownValue ===
      `/admin/withdraw/transaction?status=REJECTED&limit=${pageLimit}&skip=0`
    ) {
      dispatch(
        fetchWithdrawalReducer(
          `/admin/withdraw/transaction?status=REJECTED&limit=${pageLimit}&skip=${skipValue}&${urlQuery}`
        )
      );
    }
  };

  const nextPageHandler = () => {
    setCurrentPage((prev) => prev + 1);
    const pageNumber = currentPage + 1;
    const skipValue = (pageNumber - 1) * pageLimit;

    fetchCurrentPageDataFunction(pageLimit, skipValue);
  };

  const previousPageHandler = () => {
    setCurrentPage((prev) => prev - 1);
    const pageNumber = currentPage - 1;
    const skipValue = (pageNumber - 1) * pageLimit;

    fetchCurrentPageDataFunction(pageLimit, skipValue);
  };

  return (
    <Fragment>
      <Heading>
        <h1>Withdrawal Details</h1>
        <p>All of the Withdrawal requests</p>
      </Heading>

      <DropdownContainer>
        <Dropdown
          value={dropdownValue}
          options={dropdownOtions}
          handleChange={handleDropdownChange}
        />
      </DropdownContainer>

      <Table.SingleLine
        headers={columns}
        hasSliderEnabled={true}
        tableData={getTableDataFunction(props?.fetchedData?.fetchWithdraw)}
        additionalData={dataForSlider}
        tableClickHandler={populateSliderData}
        childClickHandler={[proceedWithdrawal, rejectWithdrawal]}
        clicked={clicked}
        setClicked={setClicked}
        callbackFunctionForTable={callbackFunctionForTable}
        tableSortFunction={tableSortFunction}
      />

      <Pagination
        currentPage={currentPage}
        nextPageHandler={nextPageHandler}
        previousPageHandler={previousPageHandler}
        isNextPageIconDisabled={currentPageItemCount?.length < pageLimit}
      />
    </Fragment>
  );
}
