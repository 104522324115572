import styled from "styled-components";

const HomeHeader = styled.div`
  margin-left: 17rem;
  margin-bottom: 3rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Paragraph = styled.p`
  color: #808288;
  font-weight: 500;
`;

const Heading = styled.h1`
  color: #4036b5;
`;

const DatePickerBox = styled.div`
  display: flex;
`;

const UsersDetailsWrapper = styled.div`
  margin-left: 17rem;
  margin-top: 4rem;
  display: flex;
  justify-content: space-around;
`;

const TotalUsersBox = styled.div`
  width: 400px;
  height: 200px;
  background-color: #d4d5d9;
  color: black;
  margin-top: 4rem;
  box-shadow: 2px 3px 4px 1px #00000035;
`;

const TotalUsersLabel = styled.h2`
  text-transform: uppercase;
  text-align: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid aliceblue;
  color: #939393;
  height: 50px;
`;

const TotalUsersBody = styled.div`
  display: grid;
  place-items: center;
  height: 150px;
`;
const TotalUsers = styled.span`
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #4036b5;
`;

const ChartWrapper = styled.div`
  margin-left: 17rem;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
`;

export {
  HomeHeader,
  TitleWrapper,
  Paragraph,
  Heading,
  DatePickerBox,
  UsersDetailsWrapper,
  TotalUsersBox,
  TotalUsersLabel,
  TotalUsersBody,
  TotalUsers,
  ChartWrapper,
};
