import styled from "styled-components";
import { Button } from "../button";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

const Wrapper = styled.div`
  margin-bottom: 2rem;
`;
const PaginationBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PaginationButton = styled(Button)`
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0 0.5rem;
  background-color: ${({ isDisabled }) => {
    return isDisabled ? "rgba(0, 0, 0, 0)" : "rgba(0, 0, 0, 0.04)";
  }};

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
`;

const PreviousIcon = styled(KeyboardArrowLeft)`
  color: ${({ disabled }) => {
    return disabled ? "rgba(0,0,0,0.4)" : "rgba(0,0,0,0.8)";
  }};
`;
const NextIcon = styled(KeyboardArrowRight)`
  color: ${({ disabled }) => {
    return disabled ? "rgba(0,0,0,0.4)" : "rgba(0,0,0,0.8)";
  }};
`;

const PageNumberWrapper = styled.div`
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0 0.5rem;
  background-color: rgba(0, 0, 0, 0.08);
  display: grid;
  place-items: center;
  cursor: default;
`;

const PageNumber = styled.span`
  font-size: 0.875rem;
  font-weight: 400;
  color: black;
`;

export {
  Wrapper,
  PaginationBox,
  PaginationButton,
  PreviousIcon,
  NextIcon,
  PageNumber,
  PageNumberWrapper,
};
