import React from "react";
import { ColumnNameWrapper, Icon, Name } from "../../compositions/table/styles";

function TableHeaer({
  header,
  totalColumns,
  setSortOrder,
  sortOrder,
  setSortBy,
  showIcon,
  tableSortFunction,
}) {
  const { icon, iconDown, iconUp, colName, isSortable, sort_by } = header;

  const sortClickHandler = () => {
    const order = sortOrder === "ASC" ? "DESC" : "ASC";

    setSortOrder(order);
    tableSortFunction(sort_by, order);
    setSortBy(sort_by);
  };

  return (
    <ColumnNameWrapper
      totalColumns={totalColumns}
      disabled={!isSortable}
      onClick={sortClickHandler}
    >
      <Name>{colName}</Name>

      {showIcon ? (
        <Icon>{sortOrder === "ASC" ? iconUp : iconDown}</Icon>
      ) : (
        <Icon>{icon}</Icon>
      )}
    </ColumnNameWrapper>
  );
}

export default TableHeaer;
