import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Outlet, Link } from "react-router-dom";
import { fetchContests } from "../challenges/fetcher";
import { fetchUpcomingChallenges } from "../upcoming-challenges/fetcher";
import { fetchChallengeTemplate } from "../create-challenge/fetcher";
import { useDispatch, useSelector } from "react-redux";
// import { getContests } from "../Challenges/slice";
import { Collapse } from "@mui/material";
import {
  AddCircle,
  CalendarMonthOutlined,
  Dashboard,
  ExpandLess,
  ExpandMore,
  TimelineOutlined,
  VerifiedUser,
  WhatshotRounded,
  Logout,
} from "@mui/icons-material";
import { setIsAuthenticate } from "../../redux/reducers/login";
const drawerWidth = 250;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Component() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [openOperations, setopenOperations] = React.useState(false);

  const handleDrawer = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const challengeHandler = () => {
    dispatch(fetchContests());
    dispatch(fetchChallengeTemplate());
  };

  const logoutHandler = () => {
    dispatch(setIsAuthenticate(false));
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex" }}>
        <Drawer variant="permanent" open={open}>
          <MenuIcon
            sx={{
              display: "flex",
              minWidth: 0,
              justifyContent: "center",
              margin: "20px",
            }}
            onClick={handleDrawer}
          />

          <Divider />

          <List style={{ color: "gray" }}>
            <ListItem
              key={"Dashboard"}
              disablePadding
              sx={{ display: "block" }}
            >
              <Link to="/" style={{ textDecoration: "none", color: "gray" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Dashboard />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Dashboard"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>

            <ListItem
              key={"Operations"}
              disablePadding
              sx={{ display: "block" }}
              onClick={() => setopenOperations(!openOperations)}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <AddCircle />
                </ListItemIcon>
                {open ? <ListItemText primary={"Operations"} /> : null}
                {openOperations ? (
                  <ExpandLess
                    sx={{ position: "absolute", marginLeft: "9.5rem" }}
                  />
                ) : (
                  <ExpandMore
                    sx={{ position: "absolute", marginLeft: "9.5rem" }}
                  />
                )}
              </ListItemButton>
            </ListItem>

            <Collapse in={openOperations} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem>
                  <Link
                    to="/Kyc"
                    style={{ textDecoration: "none", color: "gray" }}
                  >
                    <ListItemIcon>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <VerifiedUser style={{ marginLeft: "-0.8rem" }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={"KYC"}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </ListItemIcon>
                  </Link>
                </ListItem>

                <ListItem>
                  <Link
                    to="/withdrawal"
                    style={{ textDecoration: "none", color: "gray" }}
                  >
                    <ListItemIcon>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <TimelineOutlined style={{ marginLeft: "-0.8rem" }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={"Withdrawal"}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </ListItemIcon>
                  </Link>
                </ListItem>

                <ListItem>
                  <Link
                    to="/upChallenges"
                    style={{ textDecoration: "none", color: "gray" }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <CalendarMonthOutlined
                          style={{ marginLeft: "-0.8rem" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={"Upcoming Challenges"}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>

                <ListItem>
                  <Link
                    to="/challenges"
                    onClick={challengeHandler}
                    style={{ textDecoration: "none", color: "gray" }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <WhatshotRounded style={{ marginLeft: "-0.8rem" }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={"Contests"}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>

                <ListItem>
                  <ListItemButton
                    onClick={logoutHandler}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <Logout style={{ marginLeft: "-0.8rem" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Log Out"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            </Collapse>
          </List>
        </Drawer>

        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
        </Box>
      </Box>
    </React.Fragment>
  );
}
