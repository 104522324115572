import styled from "styled-components";

const ButtonWrapper = styled.button`
  background-color: ${({ variations: { typeStyle } }) => {
    if (typeStyle === "primary") {
      return "#4036B5";
    } else if (typeStyle === "secondary") {
      return "teal";
    } else if (typeStyle === "success") {
      return "#2e7d32";
    } else if (typeStyle === "error") {
      return "#d32f2f";
    }
  }};
  border: none;
  border-radius: 0.2rem;
  cursor: pointer;
`;

const ButtonLabel = styled.span`
  color: ${({ variations: { typeStyle } }) => {
    if (typeStyle === "primary" || "secondary" || "success" || "error") {
      return "#fff";
    } else {
      return "#000";
    }
  }};
`;

export { ButtonWrapper, ButtonLabel };
