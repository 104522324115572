import React from "react";
import {
  ModifiedDatePicker,
  DatePickerLabel,
  DatePickerWrapper,
} from "./style";

function DatePicker({ date, setDate, label }) {
  return (
    <>
      <DatePickerWrapper>
        <DatePickerLabel>{label}</DatePickerLabel>
        <ModifiedDatePicker
          format="dd/MM/yyyy"
          clearIcon={null}
          value={date}
          onChange={(date) => setDate(date)}
          maxDate={new Date()}
        />
      </DatePickerWrapper>
    </>
  );
}

DatePicker.defaultProps = {
  variations: {
    typeStyle: "primary",
  },
};

export default DatePicker;
